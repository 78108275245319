import { IAttachment } from 'src/services/post-service/post-service.interface';
import { resolveBEAsset } from 'src/utils/urls';
import styles from './attachment-list.module.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignupModal from '../stepper/SignupModal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactPlayer from 'react-player';
import ImageViewerModal from '../utils/imageViewerModal';

const getFileIcon = (file: { mimetype: string; originalName: string }) => {
  const extension = (file.originalName.split('.').pop() || '').toLowerCase();

  if (['xls', 'xlsx'].includes(extension)) {
    return '/images/excelFile.png';
  } else if (['pdf'].includes(extension)) {
    return '/images/pdfFile.png';
  } else if (['doc', 'docx'].includes(extension)) {
    return '/images/wordFile.png';
  } else if (['ppt', 'pptx'].includes(extension)) {
    return '/images/pptFile.png';
  } else {
    return '/images/attachmentDefault.png';
  }
};

type Props = {
  size?: 'default' | 'small';
  attachments: IAttachment[];
  data?: any;
};

export default function AttachmentList({
  attachments,
  size = 'default',
  data,
}: Props) {
  const [isPost, setIsPost] = useState(false);
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes('/post?postId=')) {
      setIsPost(true);
    }
  }, []);

  const router = useNavigate();
  const token = localStorage.getItem('token');
  const [signupModal, setSignupModal] = useState(false);
  const [currentImage, setCurrentimage] = useState('');
  const [isShowImage, setIsShowImage] = useState(false);

  return (
    <div className={styles['attachmentParent']}>
      <div className={styles.attachmentContainerPost}>
        <Carousel
          showArrows={true}
          autoPlay={false}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          useKeyboardArrows={true}
          swipeable={true}
          dynamicHeight={true}
          className={styles['image-video-carousel']}
        >
          {attachments
            .filter(
              (attachment) =>
                attachment.mimetype.startsWith('image') ||
                attachment.mimetype.startsWith('video')
            )
            .map((attachment, index) => {
              const isVideo = attachment.mimetype.startsWith('video');
              const isImage = attachment.mimetype.startsWith('image');
              return (
                <div key={index}>
                  {isVideo && (
                    <ReactPlayer
                      url={resolveBEAsset(attachment?.path || '')}
                      controls
                      playing={false}
                      width="100%"
                      height="600px"
                      muted
                    />
                  )}

                  {isImage && (
                    <div
                      key={index}
                      className={styles['zoomable-image-container']}
                      onClick={() => {
                        setCurrentimage(() =>
                          resolveBEAsset(attachment?.path || '')
                        );
                        setIsShowImage(true);
                      }}
                    >
                      <img
                        src={resolveBEAsset(attachment?.path || '')}
                        alt={attachment?.originalName}
                        className={styles['carousel-image-container']}
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </Carousel>
        <div className={styles['attachmentElseImagePost']}>
          {attachments?.map(
            (attachment: IAttachment, index: number) =>
              !attachment.mimetype.startsWith('image') &&
              !attachment.mimetype.startsWith('video') && (
                <div key={index} style={{ maxHeight: '74px' }}>
                  <>
                    <OverlayTrigger
                      key={attachment._id}
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-${attachment._id}`}>
                          {attachment.originalName}
                        </Tooltip>
                      }
                    >
                      <div
                        id="tooltip"
                        onClick={() => {
                          window.innerWidth < 700
                            ? (window.location.href = resolveBEAsset(
                                attachment?.path || ''
                              ))
                            : window.open(
                                resolveBEAsset(attachment?.path || ''),
                                '_blank'
                              );
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <div>
                            <img
                              className={
                                size === 'default'
                                  ? styles.attachmentIcon
                                  : styles.attachmentIconSmall
                              }
                              src={getFileIcon(attachment)}
                              alt={attachment.originalName}
                            />
                            <div className={styles['attachment-subtitle']}>
                              {attachment.originalName.length > 7
                                ? attachment.originalName.slice(0, 7) + '...'
                                : attachment.originalName}
                            </div>
                          </div>
                        </div>
                      </div>
                    </OverlayTrigger>
                  </>
                </div>
              )
          )}
        </div>
      </div>
      <ImageViewerModal
        isShowImage={isShowImage}
        setIsShowImage={setIsShowImage}
        currentImage={currentImage}
        setCurrentimage={setCurrentimage}
      />
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </div>
  );
}
