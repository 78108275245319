import {
  faCalendarDays,
  faCheck,
  faCircleXmark,
  faHandshake,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Input, ModalBody, Spinner } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import {
  createUserService,
  deleteUserService,
  fetchUserDetails,
  getUserService,
} from 'src/services/user-service/user-servicec';
import styles from './services.module.css';
import EventLogo from '../events/EventLogo';
import { UserContext } from 'src/context/user.context';
import { MessageContext } from 'src/context/message.context';
import MeetingAvailablityModal from '../meetings/MeetingAvailablityModal';
import ScheduleMeetingModal from '../meetings/scheduleMeetingModal';
import { IUser } from 'src/services/user-service/user-service.interface';

const Services = () => {
  const [serviceName, setServiceName] = useState('');
  const [searchParams] = useSearchParams();
  const profileId = searchParams.get('profileId');
  const loggedInUserId = localStorage.getItem('id') || '';
  const [showServiceList, setShowServiceList] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [serviceList, setServiceList] = useState<any[]>([]);
  const [addService, setAddService] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceAdded, setServiceAdded] = useState(false);
  const [addserviceError, setAddServiceError] = useState(false);

  const handleDeleteService = async (serviceId: any) => {
    deleteUserService(serviceId?._id);
    if (serviceList.length === 1) {
      setServiceList(() => []);
    }
    if (serviceList.length > 1) {
      getServiceList();
    }
  };

  const getServiceList = async () => {
    try {
      if (profileId) {
        const res = await getUserService(profileId);
        setServiceList(() => res.data);
      }
    } catch (error) {
      console.log('unable to create a service', error);
    }
  };

  const hanldeAddServiceName = async () => {
    setLoading(true);
    try {
      await createUserService(serviceName, 'www.youtube.com', serviceName);
      setLoading(false);
      setServiceAdded(true);
      setTimeout(() => {
        setServiceAdded(false);
        setServiceName('');
      }, 2500);
      getServiceList();
    } catch (error) {
      setLoading(false);
      setAddServiceError(true);
      setTimeout(() => {
        setAddServiceError(false);
      }, 5000);
      console.log('unable to create a service', error);
    }
  };

  const { id } = useContext(UserContext);
  const { fetchMessageFromId } = useContext(MessageContext);
  const handleOpenChatWindow = async () => {
    const userRes = await fetchUserDetails(profileId || '');
    await fetchMessageFromId(id, userRes);
  };
  const router = useNavigate();
  const [isMeetingModalOpen, setIsMeetingModalOpen] = useState(false);
  const [isScheduleMeetingModal, setIsScheduleMeetingModal] = useState(false);
  const handleCloseMeetingModal = () => setIsMeetingModalOpen(false);
  const self = profileId === loggedInUserId;
  const [loggedInUser, setLoggedInUser] = useState<IUser | null>(null);
  const getUserDetails = async () => {
    try {
      const userRes = await fetchUserDetails(loggedInUserId);
      setLoggedInUser(userRes);
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };
  const handleScheduleMeeting = async () => {
    if (!loggedInUser?.googleRefreshToken) {
      localStorage.setItem('redirectUrl', window.location.href);
      localStorage.setItem('scrollPosition', window.scrollY.toString());

      window.open(
        `${process.env.REACT_APP_API_HOST}/api/v1/auth/google/calendar`,
        '_self'
      );
      return getUserDetails();
    } else {
      setIsScheduleMeetingModal(!isScheduleMeetingModal);
    }
  };
  useEffect(() => {
    getServiceList();
    getUserDetails();
  }, []);

  return (
    <>
      {window.innerWidth < 700 ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            gap: '10px',
            marginTop: '20px',
          }}
        >
          <Button
            onClick={() => {
              setShowServiceModal(true);
            }}
            style={{
              cursor: 'pointer',
              border: '1px solid #000',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '20px',
              borderRadius: '16px',
              color: '#fff',
              textAlign: 'center',
              fontSize: '14px',
              fontFamily: 'Roboto, sans-serif',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '21.09px',
              background: '#333',
              width: '100%',
              height: '55px',
            }}
          >
            <>
              <FontAwesomeIcon icon={faHandshake} style={{ color: '#fff' }} />
              &nbsp;&nbsp; List of Services
            </>
          </Button>

          <Button
            onClick={() => {
              self
                ? setIsMeetingModalOpen(!isMeetingModalOpen)
                : handleScheduleMeeting();
            }}
            style={{
              cursor: 'pointer',
              border: '1px solid #000',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '20px',
              borderRadius: '16px',
              color: '#fff',
              textAlign: 'center',
              fontSize: '14px',
              fontFamily: 'Roboto, sans-serif',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '21.09px',
              background: '#333',
              width: '100%',
              height: '55px',
            }}
          >
            <>
              <FontAwesomeIcon icon={faCalendarDays} />
              &nbsp;&nbsp;
              {profileId === loggedInUserId
                ? 'Meeting Window'
                : 'Schedule Meeting'}
            </>
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            width: '100%',
            fontSize: '14px',
          }}
        >
          {profileId === loggedInUserId && (
            <Button
              style={{ width: '100%', fontSize: '14px', fontWeight: '500' }}
              onClick={() => {
                setShowServiceList(false);
                setAddService(!addService);
              }}
            >
              <>
                <EventLogo fill="#fff" />
                &nbsp;&nbsp; Add Services
              </>
            </Button>
          )}
          {addService && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '20px',
                alignItems: 'center',
                fontSize: '14px',
              }}
            >
              <Input
                value={serviceName}
                onChange={(e: any) => setServiceName(e.target.value)}
                placeholder="Enter the Service Name"
                maxLength={25}
                style={{
                  fontSize: '14px',
                }}
              />
              {window.innerWidth > 700 &&
                (loading ? (
                  <Spinner animation="border" variant="secondary" size="sm" />
                ) : !loading && serviceAdded ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : !loading && !serviceAdded && addserviceError ? (
                  <FontAwesomeIcon icon={faCircleXmark} />
                ) : (
                  <Button
                    onClick={hanldeAddServiceName}
                    style={{
                      width: '20%',
                      fontSize: '14px',
                    }}
                  >
                    Add
                  </Button>
                ))}
            </div>
          )}

          {(serviceList.length > 0 || profileId !== loggedInUserId) && (
            <Button
              style={{ width: '100%', fontSize: '14px', fontWeight: '500' }}
              onClick={() => {
                setAddService(false);
                setShowServiceList(!showServiceList);
              }}
            >
              <>
                <FontAwesomeIcon icon={faHandshake} style={{ color: '#fff' }} />
                &nbsp;&nbsp; List of Services
              </>
            </Button>
          )}

          {showServiceList && (
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              {serviceList.length > 0 ? (
                serviceList.map((service, index) => (
                  <span
                    key={service._id}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '0 12px',
                    }}
                  >
                    {index + 1}.&nbsp;{service.title}&nbsp;
                    {profileId === loggedInUserId && (
                      <FontAwesomeIcon
                        onClick={() => handleDeleteService(service)}
                        icon={faXmark}
                        style={{ color: '#e32400', cursor: 'pointer' }}
                      />
                    )}
                  </span>
                ))
              ) : (
                <div
                  style={{
                    padding: '0 6px',
                    textAlign: 'center',
                  }}
                >
                  List of Professional Services is{' '}
                  <strong>“Not Available”</strong> at a moment. <br />
                  <br />
                  Kindly, <br />
                  <Button
                    onClick={() => {
                      window?.innerWidth < 700
                        ? router(`/chatwindow?userid=${profileId}`)
                        : handleOpenChatWindow();
                    }}
                    style={{
                      width: 'max-content',
                      height: 'max-content',
                      background: '#fff',
                      color: '#1a1a1a',
                      margin: '4px 0',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}
                  >
                    Message
                  </Button>{' '}
                  <br />
                  to get a quote.
                </div>
              )}
            </div>
          )}
          <Button
            style={{ width: '100%', fontSize: '14px', fontWeight: '500' }}
            onClick={() => {
              self
                ? setIsMeetingModalOpen(!isMeetingModalOpen)
                : handleScheduleMeeting();
              //  setIsScheduleMeetingModal(!isScheduleMeetingModal);
            }}
          >
            <>
              <FontAwesomeIcon icon={faCalendarDays} />
              &nbsp;&nbsp;
              {profileId === loggedInUserId
                ? 'Meeting Window'
                : 'Schedule Meeting'}
            </>
          </Button>
        </div>
      )}
      {isMeetingModalOpen && (
        <MeetingAvailablityModal
          isOpen={isMeetingModalOpen}
          onClose={handleCloseMeetingModal}
        />
      )}

      {isScheduleMeetingModal && (
        <ScheduleMeetingModal
          isOpen={isScheduleMeetingModal}
          onClose={() => setIsScheduleMeetingModal(false)}
        />
      )}

      <Modal
        show={showServiceModal}
        onHide={() => setShowServiceModal(false)}
        toggel={() => setShowServiceModal(!showServiceModal)}
        centered
        className={styles['serviceModal']}
      >
        <ModalBody>
          {profileId === loggedInUserId && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '20px',
                alignItems: 'center',
                fontSize: '14px',
                margin: '12px 0 20px',
              }}
            >
              <Input
                value={serviceName}
                onChange={(e: any) => setServiceName(e.target.value)}
                placeholder="Enter the Service Name"
                maxLength={25}
              />

              <Button onClick={hanldeAddServiceName} style={{ width: '20%' }}>
                Add
              </Button>
            </div>
          )}
          {serviceList.length > 0 && (
            <h6 style={{ paddingLeft: '12px' }}>List of Services:</h6>
          )}

          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            {serviceList.length > 0 ? (
              serviceList.map((service, index) => (
                <span
                  key={service._id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 12px',
                  }}
                >
                  {index + 1}.&nbsp;{service.title}&nbsp;
                  {profileId === loggedInUserId && (
                    <FontAwesomeIcon
                      onClick={() => handleDeleteService(service)}
                      icon={faXmark}
                      style={{ color: '#e32400', cursor: 'pointer' }}
                    />
                  )}
                </span>
              ))
            ) : profileId !== loggedInUserId ? (
              <div
                style={{
                  padding: '0 6px',
                  textAlign: 'center',
                }}
              >
                List of Professional Services is{' '}
                <strong>“Not Available”</strong> at a moment. <br />
                <br />
                Kindly, <br />
                <Button
                  onClick={() => {
                    window?.innerWidth < 700
                      ? router(`/chatwindow?userid=${profileId}`)
                      : handleOpenChatWindow();
                  }}
                  style={{
                    width: 'max-content',
                    height: 'max-content',
                    background: '#fff',
                    color: '#1a1a1a',
                    margin: '4px 0',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}
                >
                  Message
                </Button>{' '}
                <br />
                to get a quote.
              </div>
            ) : null}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default Services;
