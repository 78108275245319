import React, { useEffect, useRef, useState } from 'react';
import Navbar from 'src/components/navbar';
import Sidebar from 'src/components/sidebar';
import classNames from 'classnames';
import styles from './layout.module.css';
import RecentEvents from 'src/components/events/RecentEvents';
import DashboardNotification from 'src/pages/notification/DashboardNotification';
import UserMembership from 'src/components/rightBar/UserMembership';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DashboardJobs from 'src/components/hiringJobs/DashboardJobs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import { IUser } from 'src/services/user-service/user-service.interface';
import { fetchUserDetails } from 'src/services/user-service/user-servicec';
import Services from 'src/components/profile/service';

const Layout = ({
  children,
  contentBordered,
}: {
  children: React.ReactNode;
  contentBordered: boolean;
}) => {
  const currentUrl = window.location.href;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isOpactity, setIsOpacity] = useState(false);
  const profileId = searchParams.get('profileId') || '';
  const loggedInUserId = localStorage.getItem('id');
  const loggedInUser = localStorage.getItem('user');
  const [user, setUser] = useState<IUser | null>(null);
  const getUserDetails = async (userId: string) => {
    try {
      const userRes = await fetchUserDetails(userId);
      setUser(userRes);
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };

  const hypertextLinkContainerRef = useRef<HTMLDivElement>(null);
  const rightbarContentRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const hypertextLinkContainer = hypertextLinkContainerRef.current;
      if (!hypertextLinkContainer) {
        return;
      }

      const offsetTop = hypertextLinkContainer.offsetTop;
      const scrollTop = window.scrollY;

      if (scrollTop > offsetTop + 70) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsSticky(false);
          }
        });
      },
      { threshold: 0.0 }
    );

    if (rightbarContentRef.current) {
      observer.observe(rightbarContentRef.current);
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (rightbarContentRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(rightbarContentRef.current);
      }
    };
  }, []);
  const [isSearchQuery, setIsSearchQuery] = useState(false);
  const [isfaded, setIsFaded] = useState(false);
  useEffect(() => {
    if (isSearchQuery || isfaded) {
      setIsOpacity(true);
    } else {
      setIsOpacity(false);
    }
  }, [isSearchQuery, isfaded]);
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);
  useEffect(() => {
    if (profileId) {
      getUserDetails(profileId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  return (
    <>
      <div className={styles['dashboard-parent']}>
        <Navbar
          isSearchQuery={isSearchQuery}
          setIsSearchQuery={setIsSearchQuery}
          isfaded={isfaded}
          setIsFaded={setIsFaded}
        />
        <div
          className={classNames(
            styles['wc-content-wrapper'],
            isOpactity ? styles.backgroundFaded : ''
          )}
        >
          {window.innerWidth >= 700 && <Sidebar />}
          <div
            className={classNames(
              styles['wc-content'],
              contentBordered ? styles['with-border'] : ''
            )}
          >
            {children}
          </div>
          {!currentUrl.includes('post') && window.innerWidth >= 1024 && (
            <div className={styles['rightbar']}>
              <div
                ref={rightbarContentRef}
                className={styles['rightbar-container']}
              >
                {!currentUrl.includes('/notification') &&
                  !currentUrl.includes('/profile') && (
                    <div className={styles['rightbar-content']}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          borderBottom: '1px solid #ccc',
                        }}
                      >
                        <div
                          style={{
                            fontSize: '14px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            marginBottom: '5px',
                            fontWeight: '600',
                            padding: '5px 0 5px 5px',
                          }}
                        >
                          Recent News / Updates
                        </div>
                        <div
                          style={{ marginRight: '10px' }}
                          className={styles['info-image-wrapper']}
                        >
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              <Tooltip
                                id={`tooltip`}
                                className={styles.tooltipOverlay}
                              >
                                All the important news, updates and judgements.
                                To learn more, click on “Read more” button
                                below.
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon icon={faLightbulb} />
                          </OverlayTrigger>
                        </div>
                      </div>

                      <div className={styles['dashboard-noti-wrapper']}>
                        <div>
                          <DashboardNotification />
                        </div>
                      </div>
                      <div
                        className={styles['rightbar-update-readMore']}
                        onClick={() => navigate('/notification')}
                      >
                        Read more &gt;&gt;{' '}
                      </div>
                    </div>
                  )}

                {!currentUrl.includes('/events') &&
                  !currentUrl.includes('/profile') && (
                    <div className={styles['rightbar-content']}>
                      <div
                        className={styles['dashboard-noti-wrapper']}
                        style={{ marginBottom: '0' }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #ccc',
                            marginBottom: '10px',
                          }}
                        >
                          <div
                            style={{
                              fontSize: '14px',
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              marginBottom: '5px',
                              fontWeight: '600',
                              padding: '5px 0 5px 5px',
                            }}
                          >
                            Upcoming Events
                          </div>
                          <div style={{ marginRight: '10px' }}>
                            <OverlayTrigger
                              placement="left"
                              overlay={
                                <Tooltip
                                  id={`tooltip`}
                                  className={styles.tooltipOverlay}
                                >
                                  Most recent upcoming events. For future
                                  events, click on “Know more” button below.
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon icon={faLightbulb} />
                            </OverlayTrigger>
                          </div>
                        </div>

                        <div>
                          <RecentEvents />
                        </div>
                      </div>
                    </div>
                  )}
                {currentUrl.includes('/profile') &&
                  profileId === loggedInUserId && <UserMembership />}
                {!currentUrl.includes('/jobs') &&
                  !currentUrl.includes('/profile') && (
                    <div className={styles['rightbar-content']}>
                      <DashboardJobs />
                    </div>
                  )}
                {currentUrl.includes('/profile') &&
                  user?.isProfessional === true && (
                    <div className={styles['rightbar-content']}>
                      <Services />
                    </div>
                  )}
                <div className={styles['hypertext-top-hr']} />
              </div>

              <div
                ref={hypertextLinkContainerRef}
                className={classNames(styles.hypertextLinkContainer, {
                  [styles.sticky]: isSticky,
                })}
              >
                <div className={styles['hypertext-link-wrapper']}>
                  <div className={styles['hypertext-link']}>
                    <a
                      style={{ color: '#000' }}
                      href="https://www.whitecollarprofessional.com/"
                    >
                      About
                    </a>
                  </div>
                  <div className={styles['hypertext-link']}>
                    <a href="https://www.whitecollarprofessional.com/services/">
                      Services
                    </a>
                  </div>
                  <div className={styles['hypertext-link']}>
                    <a href="https://www.whitecollarprofessional.com/blogs/">
                      Blogs
                    </a>
                  </div>
                </div>
                <div className={styles['hypertext-link-wrapper']}>
                  <div className={styles['hypertext-link']}>
                    <span onClick={() => navigate('/technicalsupport')}>
                      Technical Support
                    </span>
                  </div>
                  <div className={styles['hypertext-link']}>
                    <a
                      href="https://www.whitecollarprofessional.com/services/"
                      target="_self"
                      rel="noreferrer"
                    >
                      Legal Policies
                    </a>
                  </div>
                </div>
                <div className={styles['hypertext-link-wrapper']}>
                  <div className={styles['hypertext-copyRight']}>
                    <div style={{ textAlign: 'center' }}>
                      <span>© 2024 Taaran Industries Pvt. Ltd.</span>
                      <div>
                        <img
                          src="/images/logo-black.svg"
                          alt="wcplogo - White Collar Professional"
                          style={{
                            width: '100%',
                            height: 'auto',
                            marginTop: '10px',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Layout;
