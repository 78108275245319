import { Button } from 'reactstrap';
import styles from './sidebar.module.css';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LeftPannel = () => {
  const route = useNavigate();
  const signOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    route('/');
  };

  const handleAdminClick = (event, item) => {
    if (item === 'Professional Details') {
      route('/adminPannel');
    } else if (item === 'Push Notification') {
      route('/push-notification');
    } else if (item === 'Create Category') {
      route('/create-signature');
    } else if (item === 'Push Mobile Notification') {
      route('/app-notification');
    } else if (item === 'Reports') {
      route('/reports');
    } else if (item === 'Technical Support') {
      route('/tech-support');
    } else if (item === 'Daily Mails') {
      route('/email-notifications');
    }
  };

  return (
    <div className={styles['profile-sidebar']}>
      <div className={styles['profile-options']}>
        {[
          'Professional Details',
          'Push Notification',
          'Create Category',
          'Push Mobile Notification',
          'Reports',
          'Technical Support',
          'Daily Mails',
        ].map((item) => (
          <Button
            color="transparent"
            className={styles['option-item']}
            key={item}
            onClick={(event) => handleAdminClick(event, item)}
          >
            <img alt="icon" src="/images/mergerIcon.png" /> {item}
          </Button>
        ))}
      </div>

      <div
        className={classNames(
          styles['profile-options'],
          window.innerWidth > 700 ? styles['account-options'] : ''
        )}
      >
        <Button
          color="transparent"
          onClick={() => signOut()}
          className={styles['option-item']}
        >
          <img alt="icon" src="/images/mergerIcon.png" /> Sign out
        </Button>
      </div>
    </div>
  );
};

export default LeftPannel;
