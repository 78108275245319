import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { memo, useEffect } from 'react';

const Root = memo(() => {
  const currentUrl = window.location.href;
  useEffect(() => {
    if (currentUrl.includes('isApp=true')) {
      localStorage.setItem('isApp', 'true');
    }
  }, [currentUrl]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
});

export default Root;
