import { useContext, useEffect, useState } from 'react';
import Layout from '../adminDashboard/layout';
import styles from './pushNotification.module.css';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { OverlayTrigger } from 'react-bootstrap';
import { Input, Tooltip } from 'reactstrap';
import { MenuContext } from 'src/context/menu.context';
import { formatMenuToOptions } from '../create-post/create-post.helper';
import { useLoader } from 'src/context/loader.context';
import * as XLSX from 'xlsx';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import {
  createUpdate,
  sendUpdateMemberNotification,
} from 'src/services/update-service/update-service';

const DropdownIndicator = () => null;

const HelpToolTip = () => (
  <OverlayTrigger
    placement="left"
    overlay={
      <Tooltip id={`tooltip`}>
        Tags are nonetheless, but Signatures eg: NBFC, GST, Civil Law ...etc. “
        <strong>+ Create Category</strong>” to add new Tag.
      </Tooltip>
    }
  >
    <i
      className="fa fa-question-circle-o"
      style={{ fontSize: '24px', margin: '0.5rem' }}
    />
  </OverlayTrigger>
);

function PushNotification() {
  const navigate = useNavigate();
  useEffect(() => {
    const role = localStorage.getItem('role') || '';
    if (role === 'USER') {
      navigate('/dashboard');
    }
  }, []);

  const { menu } = useContext(MenuContext);
  const { hideLoader, showLoader } = useLoader();
  const [selectedDate, setSelectedDate] = useState(null);
  const [heading, setHeading] = useState('');
  const [url, setUrl] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const menuOptions = formatMenuToOptions(menu);
  const [notificationPriority, setNotificationPriority] = useState('');
  const [plainContent, setPlainContent] = useState('');
  const [notificationSource, setNotificationSource] = useState('');
  const formats = [
    'header',
    'size',
    'bold',
    'italic',
    'align',
    'underline',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
  ];
  const modules = {
    toolbar: [
      [
        { header: '2' },
        'bold',
        'italic',
        'underline',
        { list: 'ordered' },
        { list: 'bullet' },
        { align: 'center' },
        { align: 'right' },
        'link',
        'image',
      ],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageCompress: {
      quality: 0.4,
      maxWidth: 700,
      maxHeight: 700,
      imageType: 'image/jpeg',
      debug: true,
      suppressErrorLogging: false,
      insertIntoEditor: undefined,
    },
  };
  const textToHtml = (text) => {
    if (!text) return '';
    let html = text
      .replace(/\n/g, '<br />') // Converts new lines to <br>
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'); // Converts **text** to <strong>text</strong>

    // Wrap any standalone text (not already wrapped in tags) with <p> tags
    html = html
      .split('<br />')
      .map((line) => {
        return line.trim() === '' ? line : `<p>${line}</p>`;
      })
      .join('<br />');

    return html;
  };

  const handlePushNotification = async () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(plainContent, 'text/html');
    const textContent = doc.body.textContent?.trim();
    try {
      showLoader();
      const response = await createUpdate(
        heading,
        url,
        plainContent,
        selectedOption,
        selectedDate,
        notificationPriority,
        notificationSource,
        textContent
      );
      setSelectedDate(null);
      setHeading('');
      setUrl('');
      setSelectedOption([]);

      await sendUpdateMemberNotification(response?._id);
    } catch (error) {
      console.error('Error during push notification:', error);
    } finally {
      hideLoader();
    }
  };

  const [excelFile, setExcelFile] = useState(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setExcelFile(file);
  };

  const parseExcelAutoSubmit = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          raw: false,
          dateNF: 'yyyy-mm-dd',
        });

        if (parsedData.length > 1) {
          const [, ...rows] = parsedData;
          resolve(rows);
        } else {
          resolve([]);
        }
      };
      reader.readAsBinaryString(file);
    });
  };

  const handleAutoSubmit = async () => {
    if (!excelFile) {
      console.log('No Excel file uploaded');
      return;
    }

    const rows = await parseExcelAutoSubmit(excelFile);
    if (rows.length === 0) {
      console.log('No valid data found in the Excel file');
      return;
    }

    showLoader();
    for (const row of rows) {
      await submitRow(row);
    }
    hideLoader();
  };

  const submitRow = async (row) => {
    const headingVal = row[0] || '';
    const urlVal = row[1] || '';
    const tagsArray = row[2] ? row[2].split(';') : [];
    const tagNames = tagsArray.map((tag) => tag.trim());

    const selectedOptions = menuOptions.filter((option) =>
      tagNames.includes(option.label)
    );

    if (selectedOptions.length === 0) {
      console.log('No matching tags found for row:', row);
      return;
    }

    const dateStr = row[3] || '';
    const dateValue = dateStr ? new Date(dateStr) : null;

    const plainContentVal = textToHtml(row[4] || '');
    const notificationPriorityVal = row[5] || '';
    const notificationSourceVal = row[6] || '';
    const parser = new DOMParser();
    const doc = parser.parseFromString(plainContentVal, 'text/html');
    const textContentVal = doc.body.textContent?.trim();

    try {
      const response = await createUpdate(
        headingVal,
        urlVal,
        plainContentVal,
        selectedOptions,
        dateValue,
        notificationPriorityVal,
        notificationSourceVal,
        textContentVal
      );
      await sendUpdateMemberNotification(response?._id);
    } catch (error) {
      console.error('Error during auto-submit for row:', row, error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === 'notificationPriorityHigh' ||
      name === 'notificationPriorityMedium' ||
      name === 'notificationPriorityLow'
    ) {
      setNotificationPriority(value);
    }
  };

  return (
    <Layout>
      <div className={styles['wrapper']}>
        <div className={styles['heading']}>
          <div className={styles['headingTitle']}>Push Notification</div>
        </div>
        <div className={styles['inputContainer']}>
          <div className={styles['inputField']}>
            <div className={styles['inputName']}>Heading</div>
            <input
              className={styles['inputBox']}
              type="text"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
            />
          </div>
          <div className={styles['url-date-wrapper']}>
            <div className={styles['urlInputField']}>
              <div className={styles['inputName']}>Category</div>
              <div className={styles['radioContainer']}>
                <div className={styles['radioOption']}>
                  <input
                    type="radio"
                    name="notificationPriority"
                    value="high"
                    onChange={handleChange}
                    checked={notificationPriority === 'high'}
                  />
                  <span>High</span>
                </div>
                <div className={styles['radioOption']}>
                  <input
                    type="radio"
                    name="notificationPriority"
                    value="medium"
                    onChange={handleChange}
                    checked={notificationPriority === 'medium'}
                  />
                  <span>Medium</span>
                </div>
                <div className={styles['radioOption']}>
                  <input
                    type="radio"
                    name="notificationPriority"
                    value="low"
                    onChange={handleChange}
                    checked={notificationPriority === 'low'}
                  />
                  <span>Low</span>
                </div>
              </div>
            </div>

            <div className={styles['urlInputField']}>
              <div className={styles['ulrInputName']}>Post Date</div>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText="Select a date"
                className={styles['inputBox']}
              />
            </div>
          </div>

          <div className={styles['inputField']}>
            <div className={styles['inputName']}>Source</div>
            <input
              className={styles['inputBox']}
              type="text"
              value={notificationSource}
              onChange={(e) => setNotificationSource(e.target.value)}
            />
          </div>
          <div className={styles['url-date-wrapper']}>
            <div className={styles['urlInputField']} style={{ width: '100%' }}>
              <div className={styles['ulrInputName']}>URL / Hyperlink</div>
              <input
                className={styles['inputBox']}
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
          </div>

          <div className={styles['inputField']}>
            <div className={styles['inputName']}>Category / Tags</div>
            <Select
              name="tag"
              required
              isMulti
              components={{
                DropdownIndicator,
                IndicatorsContainer: HelpToolTip,
              }}
              value={selectedOption}
              onChange={(option) => setSelectedOption(option || [])}
              placeholder="Add Tags *"
              styles={{
                multiValue: (base, state) => ({
                  ...base,
                  borderRadius: '12px',
                  padding: '0px 4px',
                }),
              }}
              options={menuOptions}
            />
          </div>
          <div className={styles['quillContainer']}>
            <div
              className={styles['inputName']}
              style={{ marginBottom: '10px' }}
            >
              Description
            </div>
            <ReactQuill
              theme="snow"
              value={plainContent}
              modules={modules}
              formats={formats}
              onChange={setPlainContent}
            />
          </div>
          <div className={styles['buttonContainer']}>
            <button
              type="button"
              className={styles['submitButton']}
              onClick={() => handlePushNotification()}
            >
              Submit
            </button>
          </div>
          <hr />
          <h6 style={{ textAlign: 'center', textDecoration: 'underline' }}>
            Excel Auto-Submit{' '}
          </h6>
          <div className={styles['buttonContainer']}>
            <Input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
            />
            <button
              type="button"
              className={styles['uploadButton']}
              onClick={() => handleAutoSubmit()}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PushNotification;
