import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  postMessage,
  deleteMessage,
  getMessageForTwoMutualUser,
} from 'src/services/message-service/message-service';
import { uploadUserAttachment } from 'src/services/user-service/user-servicec';
import {
  faArrowRight,
  faClose,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MessageContext } from 'src/context/message.context';
import { dataUrlToFile, resolveBEAsset } from 'src/utils/urls';
import CloseButton from 'react-bootstrap/CloseButton';
import { IUser } from 'src/services/user-service/user-service.interface';
import { getPdfName } from 'src/utils/text';

interface Message {
  updatedOn(updatedOn: any): unknown;
}

interface GroupedMessages {
  [date: string]: Message[];
}

const ChatWindow: React.FC<any> = ({ messageListData, handleRefreshClick }) => {
  const { openChatWindow, closeChatWindow, toggleChat, isMinimized } =
    useContext(MessageContext);
  const [message, setMessage] = useState<any>('');
  const [messageList, setMessageList] = useState<any>([]);
  const [showDeleteButton, setShowDeleteButton] = useState<any>(null);
  const [selectedMessageId, setSelectedMessageId] = useState<any>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File[]>([]);
  const UserInfo: IUser = JSON.parse(localStorage.getItem('user') || '');

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (message.trim() !== '') {
        setMessage([...message, message]);
        sendMessage(e);
        setMessage('');
      }
    }
  };

  const resizeImage = (file: File, targetSizeKB: number): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.src = event.target?.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

          const maxSize = targetSizeKB * 1024;
          let width = img.width;
          let height = img.height;

          // maintaining aspect ratio
          const ratio = width / height;
          if (width > height) {
            width = Math.min(Math.sqrt(maxSize / ratio), img.width);
            height = width / ratio;
          } else {
            height = Math.min(Math.sqrt(maxSize * ratio), img.height);
            width = height * ratio;
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          const resizedDataUrl = canvas.toDataURL(file.type);
          resolve(resizedDataUrl);
        };
        img.onerror = (error) => {
          reject(error);
        };
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleDoubleClick = (messageId: any) => {
    setShowDeleteButton(true);
    setSelectedMessageId(messageId);
  };

  const handleDeleteMessage = async () => {
    try {
      await deleteMessage(selectedMessageId);
      setShowDeleteButton(false);
      getMessageOfSelectedUserWithCurrent();
    } catch (error) {
      console.error('failed', error);
    }
  };

  const sendMessage = async (e: React.MouseEvent) => {
    try {
      let attachment: any[] = [];
      if (selectedFile.length > 0) {
        const formDataArray = selectedFile.map((file) => {
          const formData = new FormData();
          formData.append('file', file);
          return formData;
        });

        attachment = await Promise.all(
          formDataArray.map((formData) =>
            uploadUserAttachment(formData, localStorage.getItem('token'))
          )
        );
      }
      let contentPayload = {
        type: 'text',
        content: message,
      };
      let payload = {
        content: contentPayload,
        userId: messageListData?.user?._id,
        attachments: attachment,
      };
      if (message || selectedFile.length > 0) {
        await postMessage(payload);
        await handleRefreshClick(e);
        await getMessageOfSelectedUserWithCurrent();
        setSelectedFile([]);
        setMessage('');
      }
    } catch (error) {
      console.error('failed', error);
    }
  };
  useEffect(() => {
    getMessageOfSelectedUserWithCurrent();
  }, [openChatWindow]);

  const getMessageOfSelectedUserWithCurrent = async (e?: React.MouseEvent) => {
    try {
      e && e.stopPropagation();
      const latestMessage = await getMessageForTwoMutualUser(
        messageListData?.user?._id,
        UserInfo?._id
      );
      setMessageList(groupMessagesByDate(latestMessage.data));
    } catch (error) {
      console.error('failed', error);
    }
  };

  const groupMessagesByDate = (messages: Message[]): GroupedMessages => {
    const groupedMessages: GroupedMessages = {};

    messages.forEach((message) => {
      const messageDate = formatDate(message.updatedOn);
      if (!groupedMessages[messageDate]) {
        groupedMessages[messageDate] = [];
      }
      groupedMessages[messageDate].push(message);
    });

    return groupedMessages;
  };

  const formatDate = (timeStamp: any) => {
    const options: any = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(timeStamp).toLocaleDateString(undefined, options);
  };

  const formatMessage = (content: any) => {
    const messageContent = content?.content;
    let slicedContent = '';
    if (messageContent) {
      slicedContent =
        messageContent.length < 200
          ? messageContent
          : `${messageContent.substring(0, 200)}...`;
    } else {
      slicedContent = messageContent;
    }
    if (typeof content === 'object') {
      if (content?.type === 'text') {
        return content?.content ? <p>{content?.content}</p> : <></>;
      } else if (content?.type === 'link') {
        return (
          <a
            key={content?.content}
            href={content?.url}
            className="messagelinkWrapper"
            dangerouslySetInnerHTML={{ __html: slicedContent }}
          />
        );
      }
    } else {
      return content;
    }
    return content;
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current?.scrollHeight;
    }
  }, [messageList]);

  const handleCloseWindow = (e: React.MouseEvent) => {
    e.stopPropagation();
    closeChatWindow(messageListData?.user?._id);
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const imageInputRef = useRef<HTMLInputElement | null>(null);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file?.type.startsWith('image/')) {
      try {
        const resizedDataUrl = await resizeImage(file, 2048); // Resize images
        const resizedFile = await dataUrlToFile(resizedDataUrl, file?.name);
        setSelectedFile((prevFiles) =>
          Array.isArray(prevFiles) ? [...prevFiles, resizedFile] : [resizedFile]
        );
      } catch (error) {
        console.error('Error resizing image:', error);
      }
    }
    event.target.value = ''; // Reset input
  };

  const handlePdfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file?.type === 'application/pdf') {
      setSelectedFile((prevFiles) =>
        Array.isArray(prevFiles) ? [...prevFiles, file] : [file]
      );
    } else {
      alert('Unsupported file type. Please select a PDF.');
    }
    event.target.value = ''; // Reset input
  };

  const handleImageUploadClick = () => {
    imageInputRef.current?.click();
  };

  const handleFileUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleDiscardFile = (index: number) => {
    setSelectedFile((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div
      className={`chat-window ${
        isMinimized.includes(messageListData?.user?._id) ? 'minimized' : ''
      }`}
    >
      <header
        className="chat-window-header"
        onClick={() => toggleChat(messageListData)}
      >
        <div className="header-content">
          <img
            src={
              messageListData?.user?.picture?.path
                ? resolveBEAsset(messageListData?.user?.picture?.path || '')
                : '/images/defaultProfilePic.svg'
            }
            style={{
              height: '33px',
              width: '33px',
              borderRadius: '50%',
              border: '1px solid #e6e6e6',
            }}
            alt={`Profile of ${messageListData?.user?.name}`}
          />
          {isMinimized.includes(messageListData?.user?._id) ? (
            <h5 style={{ margin: '0', fontSize: '14px' }}>
              {messageListData?.user?.name} {''}
              {messageListData?.user?.isProfessional && (
                <img
                  src="/images/prof-verification.png"
                  alt="VerifiedLogo"
                  style={{
                    height: '15px',
                    width: '15px',
                  }}
                />
              )}
            </h5>
          ) : (
            <a href={`/profile?profileId=${messageListData?.user?._id}`}>
              <h5 style={{ margin: '0', fontSize: '14px' }}>
                {messageListData?.user?.name} {''}
                {messageListData?.user?.isProfessional && (
                  <img
                    src="/images/prof-verification.png"
                    alt="VerifiedLogo"
                    style={{
                      height: '15px',
                      width: '15px',
                    }}
                  />
                )}
              </h5>
            </a>
          )}
        </div>
        <div className="actions">
          {isMinimized.includes(messageListData?.user?._id) ? (
            <CloseButton onClick={handleCloseWindow} />
          ) : (
            <>
              <button
                className="refresh-button"
                onClick={(e) => getMessageOfSelectedUserWithCurrent(e)}
              >
                <img
                  src="/images/refresh-cw.svg"
                  alt="refreshIcon"
                  height="18px"
                />
              </button>
              <CloseButton onClick={handleCloseWindow} />
            </>
          )}
        </div>
      </header>
      <div className="message-container" ref={scrollContainerRef}>
        {Object.keys(messageList).map((date) => (
          <div key={date}>
            <div
              style={{
                textAlign: 'center',
                color: 'var(--grey-grey-30, #b3b3b3)',
                fontSize: '0.75rem',
                letterSpacing: '0.5px',
                margin: '1rem 0',
              }}
            >
              {date}
            </div>
            <div className="message-content">
              {messageList[date].map((message: any, index: any) => (
                <div
                  key={index}
                  className={`message-row ${
                    message.userId === messageListData?.user?._id
                      ? 'sender'
                      : 'receiver'
                  }`}
                  onClick={() => handleDoubleClick(message.id)}
                >
                  <div className="img-wrapper">
                    <img
                      src={
                        message?._userId?.picture?.path
                          ? resolveBEAsset(
                              message?._userId?.picture?.path || ''
                            )
                          : '/images/defaultProfilePic.svg'
                      }
                      style={{
                        height: '15px',
                        width: '15px',
                        borderRadius: '50%',
                        border: '1px solid #e6e6e6',
                      }}
                      alt={`Profile of ${message?._userId?.name}`}
                    />
                  </div>
                  <div>
                    {message?.content?.content?.length > 0 && (
                      <div
                        style={{ display: 'flex', marginBottom: '5px' }}
                        className={`${
                          message.userId === messageListData?.user?._id
                            ? 'sender'
                            : 'receiver'
                        }`}
                      >
                        <div
                          className={`message`}
                          style={{ width: 'max-content' }}
                        >
                          {formatMessage(message?.content)}
                        </div>

                        {showDeleteButton &&
                          selectedMessageId === message.id &&
                          UserInfo._id === message?._userId?._id && (
                            <button
                              className="trash-icon"
                              onClick={handleDeleteMessage}
                            >
                              <FontAwesomeIcon icon={faTrash} size="sm" />
                            </button>
                          )}
                      </div>
                    )}
                    {message.attachments && message.attachments.length > 0 && (
                      <div
                        className="message-attachments"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '12px',
                        }}
                      >
                        {message.attachments.map(
                          (attachment: any, attachmentIndex: any) =>
                            attachment?.extension === '.pdf' ? (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                  gap: '4px',
                                }}
                                onClick={() => {
                                  window.open(
                                    `${process.env.REACT_APP_API_HOST}${attachment.path}`,
                                    '_blank'
                                  );
                                }}
                              >
                                <img
                                  src="/images/pdfIcon.svg"
                                  alt="attach"
                                  height={16}
                                  width={16}
                                />
                                <span style={{ fontSize: '12px' }}>
                                  {getPdfName(attachment?.name)}
                                </span>
                              </div>
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_API_HOST}${attachment.path}`}
                                alt={`Attachment ${attachmentIndex}`}
                                style={{
                                  height: 'auto',
                                  width: '150px',
                                  borderRadius: '8px',
                                }}
                                onClick={() =>
                                  window.open(
                                    `${process.env.REACT_APP_API_HOST}${attachment.path}`,
                                    '_blank'
                                  )
                                }
                              />
                            )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div style={{ backgroundColor: 'white' }}>
        <hr />
      </div>
      <footer className="chat-footer">
        <div>
          <textarea
            placeholder={
              selectedFile?.length > 0
                ? 'To send a text message, please remove the attached file.'
                : 'Type your message here...'
            }
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={selectedFile.length > 0}
          ></textarea>
        </div>
        <div>
          {selectedFile.map((file, index) => (
            <div key={index} className="file-details">
              <p>
                {file.name}{' '}
                <FontAwesomeIcon
                  onClick={() => handleDiscardFile(index)}
                  color="#dc3545"
                  icon={faClose}
                  style={{ cursor: 'pointer' }}
                />
              </p>
            </div>
          ))}
        </div>
        <div className="chat-footer-action">
          {!message && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              {/* Image Upload */}
              <input
                type="file"
                accept="image/*"
                id="image-upload"
                ref={imageInputRef}
                style={{ display: 'none' }}
                onChange={handleImageChange}
              />
              <label
                // htmlFor="image-upload"
                onClick={handleImageUploadClick}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2px',
                  fontSize: '12px',
                }}
              >
                <img
                  src="/images/imageIcon.svg"
                  alt="attach"
                  style={{ height: '22px', width: '18px' }}
                />

                <span>Image</span>
              </label>

              {/* PDF Upload */}
              <input
                type="file"
                accept=".pdf"
                id="file-upload"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handlePdfChange}
              />
              <label
                // htmlFor="file-upload"
                onClick={handleFileUploadClick}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2px',
                  fontSize: '12px',
                }}
              >
                <img
                  src="/images/pdfIcon.svg"
                  alt="attach"
                  style={{ height: '18px', width: '18px' }}
                />
                <span>Pdf</span>
              </label>
            </div>
          )}
          <button className="footer-send-btn" onClick={sendMessage}>
            Send
            <FontAwesomeIcon icon={faArrowRight} style={{ color: '#fff' }} />
          </button>
        </div>
      </footer>
    </div>
  );
};

export default ChatWindow;
