import { useEffect, useState } from "react";
import { Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import Layout from "../adminDashboard/layout";
import axiosInstance from "src/config/api";
import { LoadingLottie } from "../lotties/loading";
import { Pagination } from "@mui/material";
import { IUser } from "src/services/user-service/user-service.interface";

interface IReport {
  reason: string;
  userId: IUser;
  createdOn: string;
  _userId: IUser;
}

const Reports = () => {
  const [reportedData, setReportedData] = useState<IReport[]>([]);
  const [supendedData, setSuspendedData] = useState<IUser[]>([]);
  const [reportedLoader, setReportedLoader] = useState<boolean>(false);
  const [suspendedLoader, setSuspendedLoader] = useState<boolean>(false);
  const [unblockLoader, setUnblockLoader] = useState<boolean>(false);
  const [reportedPage, setReportedPage] = useState<number>(1);
  const [suspendedPage, setSuspendedPage] = useState<number>(1);
  const [totalReportedPage, setTotalReportedPage] = useState<number>(1);
  const [totalSuspendedPage, setTotalSuspendedPage] = useState<number>(1);
  const [userId, setUserId] = useState<string>('');
  const baseUrl = process.env.REACT_APP_API_HOST;

  const navigate = useNavigate();
  useEffect(() => {
    const role = localStorage.getItem('role') || '';
    if (role === 'USER') {
      navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setReportedLoader(true);
      try {
        const result = await axiosInstance.get(`${baseUrl}/api/v1/report?populate=userId&populate=_userId&pageSize=20&pageNumber=${reportedPage}${userId ? `&userId=${userId}` : ''}`)
        const resultData = result?.data;
        setTotalReportedPage(((resultData?.total || 0) / 20) || 1);
        setReportedLoader(false);

        setReportedData(() => [...resultData?.data || []]);
      } catch (error) {
        console.error('Error fetching data:', error);
        setReportedLoader(false);
      }
    };

    fetchData();
  }, [reportedPage, userId]);

  useEffect(() => {
    const fetchData = async () => {
      setSuspendedLoader(true);
      try {
        const result = await axiosInstance.get(`${baseUrl}/api/v1/user?pageSize=20&pageNumber=${suspendedPage}&status=SUSPENDED`)
        const resultData = result?.data;
        setTotalSuspendedPage(((resultData?.total || 0) / 20) || 1);
        setSuspendedLoader(false);

        setSuspendedData(() => [...resultData?.data || []]);
      } catch (error) {
        console.error('Error fetching data:', error);
        setSuspendedLoader(false);
      }
    };

    fetchData();
  }, [suspendedPage]);

  const handleUnblock = async (row: IUser) => {
    try {
      setUnblockLoader(true)
      await axiosInstance.get(`${baseUrl}/api/v1/user/${row.id}/unblock`)
      window.location.reload();
      setUnblockLoader(false)
    } catch(e) {
      console.log('unblock failed....', e)
      setUnblockLoader(false)
    }
  }

  if (reportedLoader || suspendedLoader) {
    return <LoadingLottie height={'700px'} />;
  }

  if (unblockLoader) {
    return <LoadingLottie height={'700px'} />;
  }


  return (
    <>
      <Layout contentBordered>
        <h1>Reported Users</h1>
        Search User ID: <input type="text" value={userId} onChange={(e) => setUserId(e.target.value)} />
        <Table responsive striped bordered hover>
          <thead>
            <th>Date</th>
            <th>Reason</th>
            <th>User Id</th>
            <th>Name</th>
            <th>Email</th>
            <th>Contact No</th>
            <th>Reported By ID</th>
            <th>Reported By Name</th>
          </thead>
          <tbody>
            {reportedData.map((row) => (
              <tr>
                <td>{row.createdOn}</td>
                <td>{row.reason}</td>
                <td>{row.userId?.id}</td>
                <td>{row.userId?.name}</td>
                <td>{row.userId?.emailAddress}</td>
                <td>{row.userId?.contactNumber}</td>
                <td>{row._userId?.id}</td>
                <td>{row._userId?.name}</td>
              </tr>
            ))}

          </tbody>
        </Table>
        <br />
        <Pagination
          count={totalReportedPage}
          page={reportedPage}
          onChange={(event, pageNumber) => setReportedPage(pageNumber)}
        />

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        <h1>Suspended Users</h1>
        <Table responsive striped bordered hover>
          <thead>
            <th>User Id</th>
            <th>Name</th>
            <th>Email</th>
            <th>Contact No</th>
            <th>Action</th>
          </thead>
          <tbody>
            {supendedData.map((row) => (
              <tr>
                <td>{row._id}</td>
                <td>{row.name}</td>
                <td>{row.emailAddress}</td>
                <td>{row.contactNumber}</td>
                <td><button onClick={() => handleUnblock(row)}>Unblock</button></td>
              </tr>
            ))}

          </tbody>
        </Table>
        <br />
        <Pagination
          count={totalSuspendedPage}
          page={suspendedPage}
          onChange={(event, pageNumber) => setSuspendedPage(pageNumber)}
        />
      </Layout>
    </>
  );
}

export default Reports