import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { fetchMembership } from 'src/services/user-service/user-servicec';

const StepTwoForm = ({ handleChange, formValue, onSubmit, error }) => {
  const [selectedIns, setSelectedIns] = useState([]);
  const userString = localStorage.getItem('user') || '';
  const loggedInuser = JSON.parse(userString);
  const profileId = loggedInuser._id;
  const [noMembership, setNoMembership] = useState(false);

  useState(() => {
    const newSelectedIns = [...selectedIns];
    if (formValue) {
      if (formValue.icai && !selectedIns.includes('icai')) {
        newSelectedIns.push('icai');
      }
      if (formValue.icsi && !selectedIns.includes('icsi')) {
        newSelectedIns.push('icsi');
      }
      if (formValue.icmai && !selectedIns.includes('icmai')) {
        newSelectedIns.push('icmai');
      }
      if (formValue.bci && !selectedIns.includes('bci')) {
        newSelectedIns.push('bci');
      }
      if (formValue.noneSelected && !selectedIns.includes('noneSelected')) {
        newSelectedIns.push('noneSelected');
      }

      setSelectedIns(newSelectedIns);
    }
  }, []);

  const handleSelectedIns = (e) => {
    handleChange(e);
    if (noneSelected) return;
    if (e.target.checked) {
      setSelectedIns([...selectedIns, e.target.name]);
    } else {
      setSelectedIns(selectedIns.filter((i) => i !== e.target.name));
    }
  };
  const [noneSelected, setNoneSelected] = useState(false);
  const handleNoneSelected = (e) => {
    setNoneSelected(e.target.checked);

    if (e.target.checked) {
      setSelectedIns([]);
      handleChange(e);
    }
  };
  const [icaiVerified, setIcaiVerified] = useState(false);
  const [icsiVerified, setIcsiVerified] = useState(false);
  const [icmaiVerified, setIcmaiVerified] = useState(false);
  const [bciVerified, setBciVerified] = useState(false);
  const getMembership = async () => {
    const res = await fetchMembership(profileId);
    // setMembership(res.data);
    if (res.data.length === 0) {
      setNoMembership(true);
    }
    const hasVerifiedIcaiMembership = res.data.some(
      (item) => item.name === 'ICAI' && item.isVerified === true
    );

    const hasVerifiedIcsiMembership = res.data.some(
      (item) => item.name === 'ICSI' && item.isVerified === true
    );
    const hasVerifiedIcmaiMembership = res.data.some(
      (item) => item.name === 'ICMAI' && item.isVerified === true
    );
    const hasVerifiedBviMembership = res.data.some(
      (item) => item.name === 'BCI' && item.isVerified === true
    );
    if (hasVerifiedIcaiMembership) {
      setIcaiVerified(true);
    }
    if (hasVerifiedIcsiMembership) {
      setIcsiVerified(hasVerifiedIcsiMembership);
    }
    if (hasVerifiedIcmaiMembership) {
      setIcmaiVerified(true);
    }
    if (hasVerifiedBviMembership) {
      setBciVerified(true);
    }
  };

  useEffect(() => {
    getMembership();
  }, []);

  return (
    <Fragment>
      <Row className="membership-checkbox-container">
        {!icaiVerified && (
          <Col md={6} sm={12} lg={6}>
            <div className="org-name">
              <Label className="org-name-label" htmlFor="icai">
                <Input
                  disabled={formValue.icai_disabled || noneSelected}
                  type="checkbox"
                  name="icai"
                  value={formValue.icai}
                  checked={selectedIns.includes('icai')}
                 
                  onChange={handleSelectedIns}
                  className={noneSelected ? 'none-active' : ''}
                />
                &nbsp;Chartered Accountant
              </Label>

       
                <div
                  className={`wc-form ${!selectedIns.includes('icai') ? 'not-selected' : ''}`
                }
                >
                  <div className="form-input-container">
                    <div className="input-container">
                      <Input
                        id="icai_membership_no"
                        type="text"
                        name="icai_membership_no"
                        value={formValue.icai_membership_no}
                        onChange={handleChange}
                        placeholder="Eg. 456910, 123568 ..."
                        className={classNames(
                          'form-input',
                          error.icai_membership_no
                            ? 'membership-number-error'
                            : ''
                        )}
                        disabled={
                          !selectedIns.includes('icai') ||
                          formValue.icai_disabled
                        }
                      />
                      {error.icai_membership_no && (
                        <p className="error">{error.icai_membership_no}</p>
                      )}
                    </div>
                  </div>
                </div>

            </div>
          </Col>
        )}
        {!icsiVerified && (
          <Col md={6} sm={12} lg={6}>
            <div className="org-name">
              <Label className="org-name-label" htmlFor="icsi">
                <Input
                  disabled={formValue.icsi_disabled || noneSelected}
                  type="checkbox"
                  name="icsi"
                  value={formValue.icsi}
                  checked={selectedIns.includes('icsi')}
                  onChange={handleSelectedIns}
                  className={noneSelected ? 'none-active' : ''}
                />
                &nbsp;Company Secretary
              </Label>
          
                <div
                  className={`wc-form
                    ${
                    !selectedIns.includes('icsi') ? 'not-selected' : ''
                  }`
                }
                >
                  <div className="form-input-container">
                    <div className="input-container">
                      <Input
                        id="icsi_membership_no"
                        type="text"
                        name="icsi_membership_no"
                        value={formValue.icsi_membership_no}
                        onChange={handleChange}
                        placeholder="Eg. A12345, A56789 "
                        className={classNames(
                          'form-input',
                          error.icsi_membership_no
                            ? 'membership-number-error'
                            : ''
                        )}
                        disabled={
                          !selectedIns.includes('icsi') ||
                          formValue.icsi_disabled
                        }
                      />
                      {error.icsi_membership_no && (
                        <p className="error">{error.icsi_membership_no}</p>
                      )}
                    </div>
                  </div>
                </div>
            </div>
          </Col>
         )}
        {!icmaiVerified && (
          <Col md={6} sm={12} lg={6}>
            <div className="org-name">
              <Label className="org-name-label" htmlFor="icmai">
                <Input
                  disabled={formValue.icmai_disabled || noneSelected}
                  type="checkbox"
                  name="icmai"
                  value={formValue.icmai}
                  checked={selectedIns.includes('icmai')}
                  onChange={handleSelectedIns}
                  className={noneSelected ? 'none-active' : ''}
                />
                &nbsp;Cost Accountant
              </Label>

            
                <div
                  className={`wc-form ${
                    !selectedIns.includes('icmai') ? 'not-selected' : ''
                  }`}
                >
                  <div className="form-input-container">
                    <div className="input-container">
                      <Input
                        id="icmai_membership_no"
                        type="text"
                        name="icmai_membership_no"
                        value={formValue.icmai_membership_no}
                        onChange={handleChange}
                        placeholder="Eg. 34567, 78910"
                        className={classNames(
                          'form-input',
                          error.icmai_membership_no
                            ? 'membership-number-error'
                            : ''
                        )}
                        disabled={
                          !selectedIns.includes('icmai') ||
                          formValue.icmai_disabled
                        }
                      />
                      {error.icmai_membership_no && (
                        <p className="error">{error.icmai_membership_no}</p>
                      )}
                    </div>
                  </div>
                </div>
            </div>
          </Col>
         )} 
         {!bciVerified && ( 
          <Col md={6} sm={12} lg={6}>
            <div className="org-name">
              <Label className="org-name-label" htmlFor="bci">
                <Input
                  disabled={formValue.bci_disabled || noneSelected}
                  type="checkbox"
                  name="bci"
                  value={formValue.bci}
                  checked={selectedIns.includes('bci')}
                  onChange={handleSelectedIns}
                  className={noneSelected ? 'none-active' : ''}
                />
                &nbsp;Advocate (Lawyer)
              </Label>
             
                <div
                  className={`wc-form ${
                    !selectedIns.includes('bci') ? 'not-selected' : ''
                  }`}
                >
                  <div className="form-input-container">
                    <div className="input-container">
                      <Input
                        id="bci_membership_no"
                        type="text"
                        name="bci_membership_no"
                        value={formValue.bci_membership_no}
                        onChange={handleChange}
                        placeholder="Eg. R/1234/2021, UP/123/2016"
                        className={classNames(
                          'form-input',
                          error.bci_membership_no
                            ? 'membership-number-error'
                            : ''
                        )}
                        disabled={
                          !selectedIns.includes('bci') || formValue.bci_disabled
                        }
                      />
                      {error.bci_membership_no && (
                        <p className="error">{error.bci_membership_no}</p>
                      )}
                    </div>
                  </div>

                  <div className="form-input-container"></div>
                </div>
            </div>
          </Col>
         )} 
        {noMembership && (
          <Col md={6} sm={12} lg={12}>
            <div className="org-name">
              <Label className="noneSelected" htmlFor="none">
                <Input
                  type="checkbox"
                  name="noneSelected"
                  checked={noneSelected}
                  onChange={handleNoneSelected}
                />
                &nbsp;None of the Above
              </Label>
            </div>
          </Col>
        )}
      </Row>
      <Row></Row>
      <Row className="w-75">
        <Col
          sm={12}
          className="w-100"
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            className="form-submit"
            onClick={onSubmit}
            disabled={!selectedIns.length && !noneSelected}
          >
            {<span className="font-bold-text">Submit</span>}
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default StepTwoForm;
