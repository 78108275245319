import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './imageViewerModal.module.css';

interface ImageViewerModalProps {
  isShowImage: boolean;
  setIsShowImage: (value: boolean) => void;
  currentImage: string;
  setCurrentimage: (value: string) => void;
}

export default function ImageViewerModal({
  isShowImage,
  setIsShowImage,
  currentImage,
  setCurrentimage,
}: ImageViewerModalProps) {
  return (
    <Modal
      show={isShowImage}
      statusBarTranslucent={true}
      animationType="fade"
      transparent={true}
      centered
      onHide={() => {
        setIsShowImage(!isShowImage);
        setCurrentimage('');
      }}
      className={styles['fullscreen-modal']}
    >
      <Modal.Body>
        <div style={{ display: 'flex' }}>
          <img
            src={currentImage}
            alt="postImage"
            className={styles['fullscreen-image']}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
