export interface MeetingAvailablityModalProps {
  onClose: () => void;
  isOpen: boolean;
}
export const formatDateTime = (dateString: string): string => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  const hours = date.getHours();
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const amPm = hours >= 12 ? 'PM' : 'AM';

  return `${day} ${month}, ${year} ${formattedHours}:${minutes} ${amPm}`;
};
export const formatMeetDateTime = (startTime: string | Date): string => {
  const dateTime = new Date(startTime); // Ensure it's a Date object
  return dateTime.toLocaleString('en-US', {
    timeZone: 'Asia/Kolkata',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
};
export const generateTimeOptions = () => {
  const times = [];
  for (let i = 1; i <= 12; i++) {
    times.push(`${i} AM`);
  }
  for (let i = 1; i <= 12; i++) {
    times.push(`${i} PM`);
  }
  return times;
};
type Day = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

export const daysOfWeek: Day[] = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun',
];

export const timeToMinutes = (time: string) => {
  const [hour, period] = time.split(' ');
  let [h, m] = hour.split(':').map(Number);
  if (period === 'PM' && h !== 12) h += 12; // Convert PM hours to 24-hour format
  if (period === 'AM' && h === 12) h = 0; // Convert 12 AM to 0 hours
  return h * 60 + (m || 0); // Convert to minutes
};

export const validateTime = (startTime: any, endTime: any) => {
  const startMinutes = timeToMinutes(startTime);
  const endMinutes = timeToMinutes(endTime);

  if (endMinutes <= startMinutes) {
    alert('End time must be greater than start time.');
    return false;
  }
  return true;
};

export const fullDayName: Record<string, string> = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednsday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday',
};

export const generateNextSevenDays = () => {
  const today = new Date();
  const days = [];
  for (let i = 0; i < 7; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    days.push(date.toDateString());
  }
  return days;
};

export const convertTo24HourFormat = (timeStr: string): number => {
  const [time, period] = timeStr.split(' ');
  let hour = parseInt(time, 10);
  if (period === 'PM' && hour !== 12) {
    hour += 12;
  } else if (period === 'AM' && hour === 12) {
    hour = 0;
  }
  return hour;
};

export const monthMap: Record<string, string> = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
};

export interface ScheduleMeetingModalProps {
  onClose: () => void;
  isOpen: boolean;
}
