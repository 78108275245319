import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { uploadAttachment } from 'src/services/attachment-service/attachment-service.client';
import { dataUrlToFile } from 'src/utils/urls';
import EventLogo from '../events/EventLogo';
import styles from './hiringJobs.module.css';
import districtData from 'src/geographicalDetails/district';
import { Badge } from 'reactstrap';
import classNames from 'classnames';
import SignupModal from '../stepper/SignupModal';
import AvatarEditor from 'react-avatar-editor';
import { resizeImage } from '../events/CreateEvent';
import { SuccessLottie } from '../lotties/success';
import { LoadingLottie } from '../lotties/loading';
import { FailureLottie } from '../lotties/failure';
import { createJobs } from 'src/services/job-service/job-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
export interface JobFormData {
  logoFile: any;
  logo: string;
  companyName: string;
  qualification: string;
  location: string;
  openings: string;
  experience: string;
  amount: string;
  skills: string;
  employmentType: string;
  tenure: string;
  applicationDeadline: string;
  url: string;
  description: string;
  contactPersonName: string;
  contactPersonNumber: string;
  contactPersonEmail: string;
  jobId: string;
  laptop: string;
}
export const experienceOptions = [
  '0 - 1 year',
  '1 - 3 years',
  '3 - 5 years',
  '5 - 10 years',
  '10+ years',
];
const CreateJob = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 30);
  const newDeadline = currentDate.toISOString();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const [formData, setFormData] = useState<JobFormData>({
    logoFile: null,
    logo: '',
    companyName: '', //title
    qualification: '',
    location: '',
    openings: '',
    experience: '',
    amount: '',
    skills: '',
    employmentType: '',
    tenure: '',
    applicationDeadline: newDeadline,
    url: '', //link
    description: '', //content
    contactPersonName: '',
    contactPersonNumber: '',
    contactPersonEmail: '',
    jobId: '',
    laptop: '',
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = event.target as HTMLInputElement; // Cast early for convenience
    const { name, value } = target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlParams = new URLSearchParams(searchParams);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isStep2) {
      if (formData?.tenure) {
        setIsStep2(true);
      }
    } else {
      setLoading(true);
      try {
        let attachmentRes = [];
        if (formData?.logo) {
          const imgFile = await dataUrlToFile(formData.logo);
          attachmentRes = await uploadAttachment([imgFile]);
        }
        let jobSubmitLocation = '';
        if (formData.location === 'On Site') {
          jobSubmitLocation = officeLocations.join(', ');
        } else {
          jobSubmitLocation = formData.location;
        }
        const allQualifications = qualifications.map((q) =>
          q.qualification === 'Others' ? q.otherQualification : q.qualification
        );
        const jobQualification = allQualifications.join(', ');

        await createJobs(
          attachmentRes,
          formData.companyName,
          formData.description, //content
          formData.url, //link
          formData.applicationDeadline,
          jobQualification,
          jobSubmitLocation,
          formData.openings,
          formData.experience,
          formData.amount,
          formData.skills,
          formData.employmentType,
          formData.tenure === 'Assignment Based'
            ? assignmentYears
            : formData.tenure,
          formData.contactPersonName,
          formData.contactPersonNumber,
          formData.contactPersonEmail,
          formData.jobId,
          formData.laptop
        );

        setFormData({
          logoFile: null,
          logo: '',
          companyName: '', //title
          qualification: '',
          location: '',
          openings: '',
          experience: '',
          amount: '',
          skills: '',
          employmentType: '',
          tenure: '',
          applicationDeadline: '',
          url: '', //link
          description: '', //content
          contactPersonName: '',
          contactPersonNumber: '',
          contactPersonEmail: '',
          jobId: '',
          laptop: '',
        });
        setAssignmentYears('');
        setQualifications([]);
        setOfficeLocations([]);

        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          closeModal();
          if (window.location.href.includes('refresh=true')) {
            urlParams.set('refresh', 'false');
            navigate(`/jobs?${urlParams.toString()}`);
          } else {
            urlParams.set('refresh', 'true');
            navigate(`/jobs?${urlParams.toString()}`);
          }
        }, 2500);
      } catch (error) {
        console.error('Error submitting job:', error);
        setLoading(false);
        setFailed(true);
        setTimeout(() => {
          setFailed(false);
          closeModal();
        }, 2500);
      }
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [officeLocations, setOfficeLocations] = useState<string[]>([]);

  const openModal = () => {
    if (window.innerWidth < 700) {
      localStorage.setItem('isModal', 'true');
    }
    setModalOpen(true);
  };
  const closeModal = () => {
    if (window.innerWidth < 700) {
      localStorage.removeItem('isModal');
    }

    setModalOpen(false);
  };
  const handleCloseModal = () => {
    if (window.innerWidth < 700) {
      localStorage.removeItem('isModal');
    }
    if (isStep2) {
      setIsStep2(false);
    } else {
      setModalOpen(false);
    }
  };
  const [skills, setSkills] = useState(
    formData.skills ? formData.skills.split(', ') : []
  );
  const [newSkill, setNewSkill] = useState('');

  const handleSkillBlur = () => {
    if (newSkill && skills.length < 5 && !skills.includes(newSkill.trim())) {
      const updatedSkills = [...skills, newSkill.trim()];
      setSkills(updatedSkills);
      setNewSkill('');

      const event = {
        target: {
          name: 'skills',
          value: updatedSkills.join(', '),
        },
      } as React.ChangeEvent<HTMLInputElement>;

      handleChange(event);
    }
  };

  const handleSkillRemove = (skillToRemove: string) => {
    const updatedSkills = skills.filter((skill) => skill !== skillToRemove);
    setSkills(updatedSkills);
    const event = {
      target: {
        name: 'skills',
        value: updatedSkills.join(', '),
      },
    } as React.ChangeEvent<HTMLInputElement>;

    handleChange(event);
  };
  const [assignmentYears, setAssignmentYears] = useState('');
  const token = localStorage.getItem('token') || '';
  const [signupModal, setSignupModal] = useState(false);

  const [showImageModal, setShowImageModal] = useState(false);
  const editorRef = useRef<AvatarEditor | null>(null);
  const [zoom, setZoom] = useState(0.6);
  const [image, setImage] = useState<File | null>(null);
  const handleImageChange = async (e: any) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setImage(selectedImage);

      handleShowImageModal();
    }
  };
  const handleShowImageModal = () => {
    setShowImageModal(true);
    setModalOpen(false);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setModalOpen(true);
  };
  const handleSaveImage = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage();
      const imageDataUrl = canvas.toDataURL();
      // @ts-ignore
      const imgFile = await dataUrlToFile(imageDataUrl);
      const resizedDataUrl = await resizeImage(imgFile, 128);
      // setEventsData({ ...eventsData, eventImage: resizedDataUrl });
      setFormData((prevFormData) => ({
        ...prevFormData,
        logo: resizedDataUrl,
        // logoFile: file, // Store the original file object as well
      }));
      handleCloseImageModal();
    }
  };
  const [isStep2, setIsStep2] = useState(false);
  const initialQualificationState = {
    qualification: '',
    otherQualification: '',
  };
  const [qualifications, setQualifications] = useState([
    { ...initialQualificationState },
  ]);

  const handleQualificationChange = (index: number, event: any) => {
    const { name, value } = event.target;
    const updatedQualifications = [...qualifications];
    updatedQualifications[index] = {
      ...updatedQualifications[index],
      [name]: value,
    };
    setQualifications(updatedQualifications);
  };
  const handleAddQualification = () => {
    setQualifications([
      ...qualifications,
      { qualification: '', otherQualification: '' },
    ]);
  };
  const hasQualification = qualifications.some(
    (qual) => qual.qualification || qual.otherQualification
  );

  const locationDropdownOptions = Object.entries(districtData)
    .sort(([stateA], [stateB]) => stateA.localeCompare(stateB)) // Sort by state
    .flatMap(([state, districts]) =>
      districts.map((district) => ({
        label: `${district} (${state})`, // Display both district and state
        value: district,
      }))
    );

  const handleLocationChange = (selectedOption: any, index: number) => {
    const updatedLocations = [...officeLocations];
    updatedLocations[index] = selectedOption?.value || '';
    setOfficeLocations(updatedLocations);
  };

  const addMoreLocation = () => {
    setOfficeLocations([...officeLocations, '']);
  };

  useEffect(() => {
    if (formData.location === 'On Site' && officeLocations.length === 0) {
      setOfficeLocations(['']);
    }
  }, [formData.location, officeLocations.length]);
  useEffect(() => {
    console.log(formData);
  }, [formData]);
  return (
    <>
      {window.innerWidth > 700 ? (
        <Button
          className={styles['create-jobs-btn']}
          onClick={() => {
            !token ? setSignupModal(true) : openModal();
          }}
        >
          <EventLogo fill="var(--grey-grey-00, #FFF)" />
          &nbsp; Post Job / Assignment
        </Button>
      ) : (
        <Button
          className={styles['create-job-toggle-btn-mobile']}
          onClick={() => {
            !token ? setSignupModal(true) : openModal();
          }}
        >
          <EventLogo fill="var(--grey-grey-00, #FFF)" />
        </Button>
      )}

      <Modal
        show={modalOpen}
        onHide={closeModal}
        centered
        className={classNames(
          styles['create-job-modal'],
          !isStep2 ? styles['tenureModal'] : ''
        )}
      >
        {success && <SuccessLottie height={410} />}
        {loading && <LoadingLottie height={410} />}
        {failed && <FailureLottie height={210} />}
        {!success && !loading && !failed && (
          <Form onSubmit={handleSubmit}>
            <Modal.Header
              className={classNames(styles['create-job-modal-header'])}
              closeButton
            >
              <Modal.Title>
                {!isStep2
                  ? 'Select 1 of the options from below:'
                  : formData?.tenure === 'Permanent Role'
                  ? 'Post Job / Vacancy'
                  : 'Post Work Assignment'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!isStep2 && (
                <FormGroup
                  className={classNames('mb-2', styles['job-modal-form-group'])}
                >
                  <div
                    className={classNames(
                      styles['jobs-value-radio-wrapper'],
                      styles.contractTypeWrapper
                    )}
                  >
                    <Form.Check
                      type="radio"
                      id="permanentRole"
                      label="Job Posting / Vacancy"
                      name="tenure"
                      value="Permanent Role"
                      checked={formData.tenure === 'Permanent Role'}
                      onChange={handleChange}
                    />
                    <Form.Check
                      type="radio"
                      id="assignmentBased"
                      label="Work Assignments (Days / Months)"
                      name="tenure"
                      value="Assignment Based"
                      checked={formData.tenure === 'Assignment Based'}
                      onChange={handleChange}
                    />
                  </div>
                </FormGroup>
              )}
              {isStep2 && (
                <>
                  <FormGroup
                    className={classNames(
                      'mb-2',
                      styles['job-modal-form-group']
                    )}
                  >
                    {formData.logo && (
                      <img
                        src={formData.logo}
                        alt="jobLogo-TeamWCP"
                        className={styles['createJob-selectedImage']}
                      />
                    )}
                  </FormGroup>
                  {!formData.logo ? (
                    <FormGroup
                      className={classNames(
                        'mb-2',
                        styles['job-modal-form-group']
                      )}
                    >
                      <FormLabel>Company Logo</FormLabel> :
                      <FormControl
                        name="logo"
                        onChange={handleImageChange}
                        type="file"
                        accept="image/*"
                      />
                    </FormGroup>
                  ) : (
                    <FormGroup
                      className={classNames(
                        'mb-2',
                        styles['job-modal-form-group']
                      )}
                      onClick={() =>
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          logo: '',
                        }))
                      }
                    >
                      <div className={styles['createJob-changeImageWrapper']}>
                        Change Image &nbsp;
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </div>
                    </FormGroup>
                  )}
                  <FormGroup
                    className={classNames(
                      'mb-2',
                      styles['job-modal-form-group']
                    )}
                  >
                    <FormLabel>
                      Firm Name &nbsp;
                      {!formData.companyName.length ? (
                        <span style={{ color: 'red' }}>*</span>
                      ) : (
                        ''
                      )}
                    </FormLabel>{' '}
                    :
                    <FormControl
                      type="text"
                      name="companyName"
                      placeholder="Company/Firm Name"
                      value={formData.companyName}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  {formData?.tenure === 'Permanent Role' && (
                    <FormGroup
                      className={classNames(
                        'mb-2',
                        styles['job-modal-form-group']
                      )}
                    >
                      <FormLabel>Job Id</FormLabel> :
                      <FormControl
                        type="text"
                        name="jobId"
                        placeholder="Job Id"
                        value={formData.jobId}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  )}
                  {qualifications.map((qualification, index) => (
                    <div key={index}>
                      <FormGroup
                        className={classNames(
                          'mb-2',
                          styles['job-modal-form-group']
                        )}
                      >
                        <FormLabel>
                          {index === 0 && formData.tenure === 'Permanent Role'
                            ? 'Job Qualification'
                            : index === 0 &&
                              formData.tenure === 'Assignment Based'
                            ? 'Work Qualification'
                            : 'Addn. Qualification'}
                          &nbsp;
                          {!qualification.qualification && index === 0 && (
                            <span style={{ color: 'red' }}>*</span>
                          )}
                        </FormLabel>{' '}
                        :
                        <FormControl
                          as="select"
                          name="qualification"
                          value={qualification.qualification}
                          onChange={(e) => handleQualificationChange(index, e)}
                          required
                        >
                          <option value="">Select Qualification</option>
                          <option value="Intern">Intern</option>
                          <option value="Accountant">Accountant</option>
                          <option value="Semi-qualified CA">
                            Semi-qualified CA
                          </option>
                          <option value="Semi-qualified CS">
                            Semi-qualified CS
                          </option>
                          <option value="Semi-qualified CWA">
                            Semi-qualified CWA
                          </option>
                          <option value="Chartered Accountant">
                            Chartered Accountant
                          </option>
                          <option value="Company Secretary">
                            Company Secretary
                          </option>
                          <option value="Cost Accountant">
                            Cost Accountant
                          </option>
                          <option value="Advocate (LLB)">Advocate (LLB)</option>
                          <option value="Advocate (LLM)">Advocate (LLM)</option>
                          <option value="Others">Others</option>
                        </FormControl>
                      </FormGroup>
                      {qualification.qualification === 'Others' && (
                        <FormGroup
                          className={classNames(
                            'mb-2',
                            styles['job-modal-form-group']
                          )}
                        >
                          <FormLabel>Other Qualification</FormLabel> :
                          <FormControl
                            type="text"
                            name="otherQualification"
                            placeholder="Other Qualification (max 20 characters)"
                            value={qualification.otherQualification}
                            maxLength={30}
                            onChange={(e) =>
                              handleQualificationChange(index, e)
                            }
                            required={qualification.qualification === 'Others'}
                          />
                        </FormGroup>
                      )}
                    </div>
                  ))}
                  {hasQualification && (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                      }}
                      className="mb-2"
                    >
                      <Button
                        style={{
                          background: '#1a1a1a',
                          border: '1px solid #1a1a1a',
                          padding: '2px 8px',
                          fontSize: '14px',
                        }}
                        onClick={handleAddQualification}
                      >
                        +
                      </Button>
                    </div>
                  )}

                  <FormGroup
                    className={classNames(
                      'mb-2',
                      styles['job-modal-form-group']
                    )}
                  >
                    <FormLabel>
                      Work Mode &nbsp;
                      {!formData.location.length ? (
                        <span style={{ color: 'red' }}>*</span>
                      ) : (
                        ''
                      )}
                    </FormLabel>{' '}
                    :
                    <div className={styles['jobs-value-radio-wrapper']}>
                      <Form.Check
                        type="radio"
                        id="onsite"
                        label="On Site"
                        name="location"
                        value="On Site"
                        checked={formData.location === 'On Site'}
                        onChange={handleChange}
                        required
                      />
                      <Form.Check
                        type="radio"
                        id="remote"
                        label="Remote"
                        name="location"
                        value="Remote"
                        checked={formData.location === 'Remote'}
                        onChange={handleChange}
                      />
                    </div>
                  </FormGroup>

                  {formData.location === 'On Site' && (
                    <>
                      {officeLocations.map((location, index) => (
                        <FormGroup
                          key={index}
                          className={classNames(
                            'mb-2',
                            styles['job-modal-form-group']
                          )}
                        >
                          <FormLabel>Office Location</FormLabel>:
                          {/* <FormControl
                            as="select"
                            name="officeLocation"
                            value={location}
                            onChange={(e) =>
                              handleLocationChange(index, e.target.value)
                            }
                            required={formData.location === 'On Site'}
                          >
                            <option value="" disabled>
                              Select Office Location
                            </option>
                            {Object.entries(districtData).map(
                              ([state, districts]) => (
                                <optgroup key={state} label={state}>
                                  {districts.map((district, index) => (
                                    <option key={index} value={district}>
                                      {district}
                                    </option>
                                  ))}
                                </optgroup>
                              )
                            )}
                          </FormControl> */}
                          <Select
                            className={styles['react-select-container']}
                            // classNamePrefix={styles['react-select']}
                            options={locationDropdownOptions}
                            value={locationDropdownOptions.find(
                              (option) =>
                                option.value === officeLocations[index]
                            )}
                            onChange={(selectedOption) =>
                              handleLocationChange(selectedOption, index)
                            }
                            placeholder="Select City "
                            isSearchable
                          />
                        </FormGroup>
                      ))}
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'flex-end',
                        }}
                        className="mb-2"
                      >
                        <Button
                          style={{
                            background: '#1a1a1a',
                            border: '1px solid #1a1a1a',
                            padding: '2px 8px',
                            fontSize: '14px',
                          }}
                          onClick={addMoreLocation}
                        >
                          +
                        </Button>
                      </div>
                    </>
                  )}
                  <FormGroup
                    className={classNames(
                      'mb-2',
                      styles['job-modal-form-group']
                    )}
                  >
                    <FormLabel>
                      {formData?.tenure === 'Permanent Role'
                        ? 'Designation'
                        : 'Assignment Role'}
                      &nbsp;
                      {!formData.openings.length ? (
                        <span style={{ color: 'red' }}>*</span>
                      ) : (
                        ''
                      )}
                    </FormLabel>{' '}
                    :
                    <FormControl
                      type="text"
                      name="openings"
                      placeholder={
                        formData?.tenure === 'Permanent Role'
                          ? 'Enter Designation / Role'
                          : 'Enter Assignment Name'
                      }
                      value={formData.openings}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup
                    className={classNames(
                      'mb-2',
                      styles['job-modal-form-group']
                    )}
                  >
                    <FormLabel>
                      Experience &nbsp;
                      {!formData.experience.length ? (
                        <span style={{ color: 'red' }}>*</span>
                      ) : (
                        ''
                      )}
                    </FormLabel>{' '}
                    :
                    <FormControl
                      as="select"
                      name="experience"
                      value={formData.experience}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled>
                        Select Experience
                      </option>
                      {experienceOptions.map((experience, index) => (
                        <option key={index} value={experience}>
                          {experience}
                        </option>
                      ))}
                    </FormControl>
                  </FormGroup>
                  <FormGroup
                    className={classNames(
                      'mb-2',
                      styles['job-modal-form-group']
                    )}
                  >
                    <FormLabel>Amount</FormLabel> :
                    <FormControl
                      type="text"
                      name="amount"
                      placeholder="CTC in INR (₹)"
                      value={formData.amount}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup
                    className={classNames(
                      'mb-2',
                      styles['job-modal-form-group']
                    )}
                  >
                    <FormLabel>Skills Required</FormLabel> :
                    <div className="mb-2" style={{ width: '100%' }}>
                      <FormControl
                        type="text"
                        name="newSkill"
                        placeholder="Max. upto 5 skills"
                        value={newSkill}
                        maxLength={30}
                        onChange={(e) => setNewSkill(e.target.value)}
                        onBlur={handleSkillBlur}
                      />
                    </div>
                  </FormGroup>
                  {skills.length > 0 && (
                    <div
                      className="mb-2"
                      style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}
                    >
                      {skills.map((skill, index) => (
                        <Badge
                          key={index}
                          pill
                          bg="secondary"
                          styles={{ padding: '4px 9px', fontSize: '12px' }}
                        >
                          {skill}{' '}
                          <Button
                            variant="light"
                            size="sm"
                            onClick={() => handleSkillRemove(skill)}
                            className={styles['skills-cross-btn']}
                          >
                            x
                          </Button>
                        </Badge>
                      ))}
                    </div>
                  )}
                  {formData.tenure === 'Permanent Role' && (
                    <FormGroup
                      className={classNames(
                        'mb-2',
                        styles['job-modal-form-group']
                      )}
                    >
                      <FormLabel>
                        Employment Type &nbsp;
                        {!formData.employmentType.length ? (
                          <span style={{ color: 'red' }}>*</span>
                        ) : (
                          ''
                        )}
                      </FormLabel>{' '}
                      :
                      <div className={styles['jobs-value-radio-wrapper']}>
                        <Form.Check
                          type="radio"
                          id="fullTime"
                          label="Full Time"
                          name="employmentType"
                          value="Full Time"
                          checked={formData.employmentType === 'Full Time'}
                          onChange={handleChange}
                          required
                        />
                        <Form.Check
                          type="radio"
                          id="partTime"
                          label="Part Time"
                          name="employmentType"
                          value="Part Time"
                          checked={formData.employmentType === 'Part Time'}
                          onChange={handleChange}
                        />
                      </div>
                    </FormGroup>
                  )}
                  {/* {formData.tenure === 'Assignment Based' && (
                  <FormGroup
                    className={classNames('mb-2', styles['job-modal-form-group'])}
                  >
                    <FormLabel>Tenure in Months:</FormLabel> :
                    <FormControl
                      type="number"
                      name="assignmentYears"
                      placeholder="Enter number of months"
                      value={assignmentYears}
                      onChange={(e) => setAssignmentYears(e.target.value)}
                      required
                    />
                  </FormGroup>
                )} */}
                  {formData.tenure === 'Assignment Based' && (
                    <FormGroup
                      className={classNames(
                        'mb-2',
                        styles['job-modal-form-group']
                      )}
                    >
                      <FormLabel>
                        Tenure in Days:{' '}
                        {!assignmentYears.length ? (
                          <span style={{ color: 'red' }}>*</span>
                        ) : (
                          ''
                        )}
                      </FormLabel>{' '}
                      :
                      <FormControl
                        type="number"
                        name="assignmentYears"
                        placeholder="Number of days"
                        value={assignmentYears}
                        onChange={(e) => setAssignmentYears(e.target.value)}
                        required
                      />
                    </FormGroup>
                  )}
                  <FormGroup
                    className={classNames(
                      'mb-2',
                      styles['job-modal-form-group']
                    )}
                  >
                    <FormLabel>
                      Laptop Required &nbsp;
                      {!formData.location.length ? (
                        <span style={{ color: 'red' }}>*</span>
                      ) : (
                        ''
                      )}
                    </FormLabel>{' '}
                    :
                    <div className={styles['jobs-value-radio-wrapper']}>
                      <Form.Check
                        type="radio"
                        id="laptop"
                        label="Yes"
                        name="laptop"
                        value="Yes"
                        checked={formData.laptop === 'Yes'}
                        onChange={handleChange}
                        required
                      />
                      <Form.Check
                        type="radio"
                        id="laptop"
                        label="No"
                        name="laptop"
                        value="No"
                        checked={formData.laptop === 'No'}
                        onChange={handleChange}
                      />
                    </div>
                  </FormGroup>
                  {formData?.tenure === 'Permanent Role' && (
                    <FormGroup
                      className={classNames(
                        'mb-2',
                        styles['job-modal-form-group']
                      )}
                    >
                      <FormLabel>Application Link</FormLabel> :
                      <FormControl
                        type="text"
                        name="url"
                        placeholder="URL"
                        value={formData.url}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  )}
                  <FormGroup
                    className={classNames(
                      'mb-2',
                      styles['job-modal-form-group']
                    )}
                  >
                    <FormLabel>Due Date</FormLabel> :
                    <FormControl
                      type="date"
                      name="applicationDeadline"
                      value={formData.applicationDeadline}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup
                    className={classNames(
                      'mb-2',
                      styles['job-modal-form-group']
                    )}
                  >
                    <FormLabel>Contact Name</FormLabel> :
                    <FormControl
                      type="text"
                      name="contactPersonName"
                      placeholder="Contact Name"
                      value={formData.contactPersonName}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup
                    className={classNames(
                      'mb-2',
                      styles['job-modal-form-group']
                    )}
                  >
                    <FormLabel>Contact Number</FormLabel> :
                    <FormControl
                      type="text"
                      name="contactPersonNumber"
                      placeholder="Contact Number"
                      value={formData.contactPersonNumber}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup
                    className={classNames(
                      'mb-2',
                      styles['job-modal-form-group']
                    )}
                  >
                    <FormLabel>Contact Email</FormLabel> :
                    <FormControl
                      type="email"
                      name="contactPersonEmail"
                      placeholder="Contact E-mail Id"
                      value={formData.contactPersonEmail}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup
                    className={classNames(
                      'mb-2',
                      styles['job-modal-form-group']
                    )}
                    style={{
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div className={styles['desc-label-wrapper']}>
                      <FormLabel>Description </FormLabel>:
                    </div>

                    <FormControl
                      as="textarea"
                      name="description"
                      placeholder={
                        formData.tenure === 'Permanent Role'
                          ? 'Enter Job Description here...'
                          : 'Enter Work Assignment here...'
                      }
                      value={formData.description}
                      type="textarea"
                      onChange={handleChange}
                      rows={4}
                      className={styles['job-desc-textArea']}
                    />
                  </FormGroup>
                </>
              )}
            </Modal.Body>
            <Modal.Footer className={styles['create-job-modal-footer']}>
              <span onClick={handleCloseModal}>
                {isStep2 ? 'Back' : 'Cancel'}
              </span>
              <Button type="submit" variant="secondary">
                {isStep2 ? 'Submit' : 'Next'}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Modal>
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
      <Modal
        show={showImageModal}
        onHide={handleCloseImageModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className={styles['image-modal-header']} closeButton>
          <Modal.Title className={styles['image-modal-header-title']}>
            Edit Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {image && (
            <div className={styles['edit-image-modal']}>
              <AvatarEditor
                ref={editorRef}
                image={image}
                border={0}
                color={[255, 255, 255, 0.9]}
                scale={zoom}
                // rotate={rotate}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className={styles['edit-image-footer']}>
            <div className={styles['zoom-rotate-wrapper']}>
              <div className={styles['zoom-labe-input-wrapper']}>
                <label className={styles['zoom-rotate-label']}>Zoom:</label>
                <Form.Control
                  type="range"
                  min="0.6"
                  max="3"
                  step="0.1"
                  value={zoom}
                  onChange={(e) => setZoom(parseFloat(e.target.value))}
                  className={styles['zoom-rotate-input']}
                />
              </div>
            </div>
            <div className={styles['edit-img-savecancel-wrapper']}>
              <Button
                className={styles['image-modal-cancel']}
                onClick={handleCloseImageModal}
              >
                Cancel
              </Button>
              <Button
                variant="secondary"
                onClick={handleSaveImage}
                className={styles['image-modal-save']}
              >
                Save Image
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateJob;
