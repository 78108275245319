import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import axiosInstance from 'src/config/api';
import styles from './login-btns.module.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Alert } from 'reactstrap';
import {
  changePasswordOTP,
  sendOTPForgotPassword,
} from 'src/services/user-service/user-servicec';

const message = `Thank you for Joining The WCP Community ! Currently your Account is under Review, will get active within 24-48 Hrs`;
const suspendedMessage = `Your Profile is under review as many have reported you`;

export default function DashboardLogin() {
  const baseUrl = process.env.REACT_APP_API_HOST;
  // const currentUrl = window.location.href;
  const navigate = useNavigate();
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [email, setEmail] = useState('');
  const lowerCaseEmail = email.toLowerCase();
  const [pass, setPass] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [showSuspendedMsg, setShowSuspendedMsg] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');

  const [forgotPasswordOtp, setForgotPasswordOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorResetEmail, setResetErrorEmail] = useState('');
  const [errorOtp, setErrorOtp] = useState('');
  const [errorNewPassword, setErrorNewPassword] = useState('');
  const [errorConfirmNewPassword, setErrorConfirmNewPassword] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogin = async () => {
    if (!lowerCaseEmail) {
      setErrorEmail('Please fill in your Email-ID.');
    } else {
      setErrorEmail('');
    }

    if (!pass) {
      setErrorPassword('Please fill in your Password.');
    } else {
      setErrorPassword('');
    }

    if (!lowerCaseEmail || !pass) {
      return;
    }

    try {
      const res = await axiosInstance.post(`${baseUrl}/api/v1/token`, {
        emailAddress: lowerCaseEmail,
        password: pass,
      });
      localStorage.setItem('user', JSON.stringify(res.data));
      localStorage.setItem('id', res.data._id);
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('role', res.data.role);

      if (
        res.data.status === 'ACTIVE' &&
        ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(res.data.role)
      ) {
        navigate('/adminPannel');
      } else if (res.data.status === 'ACTIVE' && res.data.role === 'USER') {
        window.location.reload();
        navigate('/dashboard');
      } else {
        if(res.data.status === 'SUSPENDED') {
          setShowSuspendedMsg(true)
        }
        setEmail('');
        setPass('');
        localStorage.removeItem('token');
        setShowModal(true);
        setIncorrectPassword(true); // Set incorrectPassword to true if the login fails
      }

      setShowLoginForm(!showLoginForm);
    } catch (e) {
      setEmail('');
      setPass('');
      localStorage.removeItem('token');
      // setShowModal(true);
      setIncorrectPassword(true); // Set incorrectPassword to true if the login fails
    }
  };

  const handleLoginKeyPress = (event: any) => {
    if (event.code === 'Enter') {
      handleLogin();
    }
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function isValidEmail(email: string) {
    return emailRegex.test(email);
  }

  const handlePasswordRecovery = async () => {
    //TODO: update form validation in reset password

    if (!forgotPasswordEmail || !isValidEmail(forgotPasswordEmail)) {
      setResetErrorEmail('Please fill in valid Email-Id.');
      return;
    } else {
      setResetErrorEmail(''); // Clear the error message
    }

    if (!forgotPasswordOtp) {
      setErrorOtp('Please fill in your OTP from Email-Id.');
      return;
    } else {
      setErrorOtp(''); // Clear the error message
    }

    // Check if new password is empty
    if (!newPassword) {
      setErrorNewPassword('Please fill in your New Password.');
      return;
    } else {
      setErrorNewPassword(''); // Clear the error message
    }

    // Check if confirm new password is empty
    if (!confirmNewPassword) {
      setErrorConfirmNewPassword('Please confirm your New Password.');
      return;
    } else {
      setErrorConfirmNewPassword(''); // Clear the error message
    }

    // Check if new password and confirm new password match
    if (newPassword !== confirmNewPassword) {
      setErrorNewPassword('Passwords do not match.');
      setErrorConfirmNewPassword('Passwords do not match.');
      setPasswordMatchError(true);
      setPasswordsMatch(false);
      return;
    }

    setPasswordMatchError(false);
    setPasswordsMatch(true);

    try {
      const lowerCaseForgotEmail = forgotPasswordEmail.toLowerCase();
      await changePasswordOTP(
        lowerCaseForgotEmail,
        forgotPasswordOtp,
        newPassword
      );
      setShowForgotPasswordModal(false);
      setShowLoginForm(false);
    } catch (e: any) {
      alert(e.message);
    }
  };

  const resetFields = () => {
    setEmail('');
    setPass('');
    setShowPassword(false);
    setShowForgotPasswordModal(false);
    setConfirmNewPassword('');
    setForgotPasswordEmail('');
    setNewPassword('');
    setErrorEmail('');
    setErrorPassword('');
    setResetErrorEmail('');
    setErrorNewPassword('');
    setErrorConfirmNewPassword('');
    setIncorrectPassword(false);
  };

  const handleLoginClose = () => {
    resetFields();
    setShowLoginForm(false);
  };

  const handleClose = () => {
    setShowModal(false);
    resetFields();
  };

  const handleConfirmStatus = () => {
    resetFields();
    setShowModal(false);
  };

  function handleForgotPasswordClose(): void {
    setShowForgotPasswordModal(false);
    resetFields();
  }

  async function handleGetOtp(): Promise<void> {
    try {
      await sendOTPForgotPassword(forgotPasswordEmail);
      startTimer();
    } catch (e: any) {
      setErrorOtp(e.message as string);
    }
  }
  const [otpTimer, setOtpTimer] = useState(30);
  const [otpTimerActive, setOtpTimerActive] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const startTimer = () => {
    setOtpTimer(30);
    setOtpTimerActive(true);
    setIsButtonDisabled(true);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (otpTimerActive && otpTimer > 0) {
      timer = setTimeout(() => {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (otpTimer === 0) {
      setOtpTimerActive(false);
      setIsButtonDisabled(false);
    }

    return () => clearTimeout(timer);
  }, [otpTimer, otpTimerActive]);

  return (
    <>
      {showModal && (
        <div>
          <Modal show={showModal} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>
                <strong>{showSuspendedMsg ? suspendedMessage : message}</strong>
              </span>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => handleConfirmStatus()}>
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      <div>
        <div className={styles['email-pass-wrapper']} style={{ width: '100%' }}>
          <div className="mb-3">
            <label>Email address</label>
            <input
              type="email"
              value={email}
              className={classNames('form-control', {
                [styles['red-border']]: errorEmail,
              })}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
              required
            />
            {errorEmail && <p style={{ color: 'red' }}>{errorEmail}</p>}
          </div>
          <div className="mb-3 position-relative">
            <label>Password</label>
            <>
              <input
                type={showPassword ? 'text' : 'password'}
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                onKeyDown={(e) => handleLoginKeyPress(e)}
                className={classNames(
                  'form-control',

                  {
                    [styles['incorrect-password']]: errorPassword,
                    [styles['red-border']]: errorPassword,
                  }
                )}
                placeholder="Enter password"
                required
              />

              <button
                className={styles['password-toggle']}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </button>
            </>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <button
              // className=
              className={classNames(
                styles['forgotpassword-btn'],
                'btn btn-link'
              )}
              onClick={() => {
                setShowForgotPasswordModal(true);
                setShowLoginForm(false);
              }}
            >
              Forgot Password?
            </button>
            <Button
              variant="secondary"
              onClick={() => handleLogin()}
              disabled={!pass || !email}
              className={styles['login-btn']}
            >
              Login
            </Button>
          </div>{' '}
          {errorPassword && (
            <p style={{ color: 'red', marginTop: '-15px' }}>{errorPassword}</p>
          )}
          {incorrectPassword && (
            <Alert color="danger">Password or username is incorrect</Alert>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            color: 'rgb(115, 115, 115, 1)',
            margin: '20px auto 20px auto',
            width: '75%',
          }}
        >
          <div
            style={{
              flexGrow: '1',
              height: '1px',
              backgroundColor: 'rgb(219, 219, 219)',
            }}
          ></div>
          <div
            style={{
              padding: '0 10px',
              fontSize: '10px',
              fontFamily: 'Roboto',
            }}
          >
            OR
          </div>
          <div
            style={{
              flexGrow: '1',
              height: '1px',
              backgroundColor: 'rgb(219, 219, 219)',
            }}
          ></div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src="/images/signinGoogle.png"
            alt="googleSigninLogo"
            className={styles['signinGoogle-img']}
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_API_HOST}/api/v1/auth/google`,
                '_self'
              )
            }
          />
        </div>
      </div>

      <Modal
        show={showForgotPasswordModal}
        className={styles['reset-password-modal']}
        onHide={() => handleForgotPasswordClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="mb-3">
              <label>
                Email Address{' '}
                <span style={{ color: 'red' }}>
                  {!forgotPasswordEmail ? '*' : ''}
                </span>
              </label>
              <input
                type="email"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                className={classNames('form-control', {
                  [styles['red-border']]:
                    errorResetEmail && forgotPasswordEmail.length === 0, // Apply red border if passwords don't match
                })}
                placeholder="Enter email"
                required
              />
              {errorResetEmail && (
                <p style={{ color: 'red', padding: '2px' }}>
                  {errorResetEmail}
                </p>
              )}
            </div>
            <div className="mb-3">
              <label>OTP</label>
              <div className={styles.otpContainer}>
                <input
                  type="text"
                  value={forgotPasswordOtp}
                  onChange={(e) => setForgotPasswordOtp(e.target.value)}
                  placeholder="Enter OTP"
                  required
                  className="form-control"
                />
                <button
                  className="btn"
                  onClick={handleGetOtp}
                  disabled={isButtonDisabled}
                >
                  Get OTP
                </button>
              </div>
              {otpTimerActive && (
                <p style={{ color: 'red' }}>Resend OTP in {otpTimer} seconds</p>
              )}
              {errorOtp && !otpTimerActive && (
                <p style={{ color: 'red' }}>{errorOtp}</p>
              )}
            </div>
            <div className="mb-3 position-relative">
              <label>
                New Password{' '}
                <span style={{ color: 'red' }}>{!newPassword ? '*' : ''}</span>
              </label>
              <div className={styles['password-input']}>
                <div>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className={classNames('form-control', {
                      [styles['red-border']]: newPassword.length === 0,
                      [styles['red-border']]:
                        newPassword !== confirmNewPassword, // Apply red border if passwords don't match
                    })}
                    placeholder="Enter new password"
                    required
                  />

                  <button
                    className={styles['password-toggle-forgot']}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>{' '}
                {errorNewPassword && (
                  <p style={{ color: 'red' }}>{errorNewPassword}</p>
                )}
              </div>
            </div>

            <div className="mb-3">
              <label>
                Confirm New Password{' '}
                <span style={{ color: 'red' }}>
                  {!confirmNewPassword ? '*' : ''}
                </span>
              </label>
              <div className="password-input">
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  className={classNames('form-control', {
                    [styles['red-border']]: newPassword !== confirmNewPassword, // Apply red border if passwords don't match
                  })}
                  placeholder="Confirm new password"
                  required
                />
                {errorConfirmNewPassword && (
                  <p style={{ color: 'red' }}>{errorConfirmNewPassword}</p>
                )}
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="mb-3"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <button
              className="btn btn-link"
              onClick={() => handleForgotPasswordClose()}
            >
              Cancel
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => handlePasswordRecovery()}
            >
              Reset Password
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
