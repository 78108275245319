import React, { useState } from 'react';
import Layout from '../adminDashboard/layout';
import { triggerDailySummaryMails } from 'src/services/post-service/post-service';

type Props = {};

export default function DailyMailsTrigger({}: Props) {
  const [delayTime, setDelayTime] = useState<{ [key: string]: number }>({
    insights: 15000,
    queries: 15000,
    events: 15000,
    updates: 15000,
    jobs: 15000,
  });

  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({
    insights: false,
    queries: false,
    events: false,
    updates: false,
    jobs: false,
  });

  const handleButtonClick = async (mailType: string, delay: number) => {
    setLoadingStates((prev) => ({ ...prev, [mailType]: true }));
    try {
      await triggerDailySummaryMails(mailType, delay);
    } catch (error) {
      console.error(`Error triggering ${mailType} mail:`, error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [mailType]: false }));
    }
  };

  return (
    <Layout contentBordered>
      <div className="container py-4">
        <div className="row mb-4">
          <div className="col-12">
            <p className="text-muted">
              Set the delay time (in milliseconds) between each mail trigger.
              For example, a delay of 15000ms (15 seconds) will send 4 mails in
              a minute. Adjust the delay as per your requirement to avoid
              exceeding limits.
            </p>
          </div>
        </div>

        {[
          { id: 'insights', label: 'Trigger Daily Insights Summary Mail' },
          { id: 'queries', label: 'Trigger Daily Queries Summary Mail' },
          { id: 'events', label: 'Trigger Daily Events Summary Mail' },
          { id: 'updates', label: 'Trigger Daily Updates Summary Mail' },
          { id: 'jobs', label: 'Trigger Daily Jobs Posted Mail' },
        ].map((item) => (
          <div className="row mb-4 align-items-center" key={item.id}>
            <div className="col-md-4">
              <label htmlFor={`${item.id}Delay`} className="form-label">
                {item.label}
              </label>
            </div>
            <div className="col-md-4">
              <div className="input-group">
                <input
                  type="number"
                  name={`${item.id}Delay`}
                  id={`${item.id}Delay`}
                  className="form-control"
                  placeholder="Enter delay (ms)"
                  value={delayTime[item.id]}
                  onChange={(e) =>
                    setDelayTime({
                      ...delayTime,
                      [item.id]: Number(e.target.value),
                    })
                  }
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() =>
                    setDelayTime({ ...delayTime, [item.id]: 15000 })
                  }
                >
                  Default: 15s
                </button>
              </div>
            </div>
            <div className="col-md-2">
              <input
                type="range"
                className="form-range"
                min="1000"
                max="60000"
                step="1000"
                value={delayTime[item.id]}
                onChange={(e) =>
                  setDelayTime({
                    ...delayTime,
                    [item.id]: Number(e.target.value),
                  })
                }
              />
            </div>
            <div className="col-md-2">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={() => handleButtonClick(item.id, delayTime[item.id])}
                disabled={loadingStates[item.id]}
              >
                {loadingStates[item.id] ? 'Sending...' : 'Trigger'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
}
