import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from 'reactstrap';
import styles from './dashboardList.module.css';
import {
  faCaretUp,
  faCheck,
  faEllipsisVertical,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Request } from 'src/components/request';
import {
  deletePost,
  deletePostUpvote,
  getPost,
  postPostUpvote,
} from 'src/services/post-service/post-service';
import { IPost, Pin } from 'src/services/post-service/post-service.interface';
import { IUser } from 'src/services/user-service/user-service.interface';
import { formatDateStr } from 'src/utils/dateUtil';
import AttachmentList from '../../attachment-list/attachment-list';
import AvatarRow from '../../avatar-row/avatar-row';
import { handleRequestSend } from 'src/utils/messageUtils';
import SignupModal from 'src/components/stepper/SignupModal';
import PinButton from 'src/components/pin-button';
import CreatePost from 'src/components/create-post';
import CommentsList from '../commentsList/commentsList';

const DashboardListItem = ({
  postData: originalData,
  profilePage = false,
  onPostDelete,
}: {
  postData: IPost;
  profilePage?: boolean;
  onPostDelete?(postId: any): void;
}) => {
  const [data, setData] = useState<IPost | undefined>();
  const [loadingUpvotes, setLoadingUpvotes] = useState(false);
  const token = localStorage.getItem('token');
  const [signupModal, setSignupModal] = useState(false);
  const user = JSON.parse(localStorage.getItem('user') || '{}') as IUser;
  const loggedInUserId = localStorage.getItem('id') || '';
  const [updating, setUpdating] = useState(false);
  const [showQuestionMenu, setShowQuestionMenu] = useState(false);
  const [editQuestion, setEditQuestion] = useState<IPost>();
  const isWork = data?.type === 'WORK';
  const handleDelete = async () => {
    if (data) {
      setUpdating(true);
      try {
        await deletePost(data?._id);
        if (onPostDelete) {
          onPostDelete(data?._id);
        }
      } catch (error) {}

      setUpdating(false);
    }
  };

  const handleEdit = () => {
    setSelectedQuestion(undefined);
    setEditQuestion(() => data);
    setShow(true);
  };

  const self = data?._userId?._id?.toString() === loggedInUserId;
  const postPageUrl = `https://community.whitecollarprofessional.com/post`;
  const [isCopying, setIsCopying] = useState(false);

  const handleCopyPost = (data: any) => {
    setIsCopying(true);
    navigator.clipboard.writeText(`${postPageUrl}?postId=${data?._id}`);
    setTimeout(() => {
      setIsCopying(false);
    }, 1000);
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const contentRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const isContentClamped =
        contentRef.current.scrollHeight > contentRef.current.offsetHeight;
      setIsClamped(isContentClamped);
    }
  }, [data?.content]);

  const handleToggleContent = () => {
    if (isExpanded && dashboardListItemRef.current) {
      // Get the top position of the element relative to the document
      const topPosition =
        dashboardListItemRef.current.getBoundingClientRect().top +
        window.scrollY;

      // Scroll the window to the top position of the element
      window.scrollTo({
        top: topPosition - 64,
        behavior: 'auto', // Smooth scrolling
      });
    }
    setIsExpanded((prev) => !prev);
  };

  const [isShowComment, setIsShowComments] = useState(false);
  const showComments = () => {
    setIsShowComments(!isShowComment);
  };
  const [show, setShow] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<IPost>();

  const updatePost = async (postId: any) => {
    const res = await getPost(postId);
    if (data) {
      setData((prev: any) => {
        if (!data) return prev;
        return { ...prev, commentCount: res?.commentCount || 0 };
      });
    }
  };

  useEffect(() => {
    if (originalData) {
      setData(originalData);
    }
  }, [originalData]);

  const isUserVoted = () =>
    data?.upvotes?.some(
      (upvote) => upvote?._userId.toString() === loggedInUserId
    );

  const [isVoted, setIsVoted] = useState(isUserVoted);
  useEffect(() => {
    setIsVoted(isUserVoted());
  }, [data?.upvotes]);

  const addUpvote = async () => {
    if (!data) return;

    try {
      setLoadingUpvotes(true);

      const upvote = await postPostUpvote(data._id);

      setData((prevData) => {
        if (!prevData) return prevData;
        return {
          ...prevData,
          upvotes: prevData?.upvotes?.concat(upvote),
          upvoteCount: (prevData.upvoteCount || 0) + 1,
        };
      });
    } catch (error) {
      console.error('Error adding upvote:', error);
    } finally {
      setLoadingUpvotes(false);
    }
  };

  const removeUpvote = async () => {
    if (!data) return;

    try {
      setLoadingUpvotes(true);
      await deletePostUpvote(data._id, loggedInUserId);
      setData((prevData) => {
        if (!prevData) return prevData; // Safeguard
        return {
          ...prevData,
          upvotes: prevData?.upvotes?.filter(
            (upvote) => upvote?._userId.toString() !== loggedInUserId
          ),
          upvoteCount: prevData.upvoteCount ? prevData.upvoteCount - 1 : 0,
        };
      });
    } catch (error) {
      console.error('Error removing upvote:', error);
    } finally {
      setLoadingUpvotes(false);
    }
  };
  const dashboardListItemRef = useRef<HTMLDivElement | null>(null);
  return (
    <>
      {data && (
        <div
          className={styles['dashboard-list-item']}
          ref={dashboardListItemRef}
        >
          {window.innerWidth < 700 ? (
            <div className={styles['item-windows-header']}>
              <AvatarRow showMembership data={data._userId as IUser} />
              <div className={styles['postType-date-wrapper']}>
                <span className={styles['post-date']}>
                  {formatDateStr(data?.createdOn as string)}
                </span>
                <div
                  className={styles['item-title']}
                  style={{ marginBottom: '0' }}
                >
                  {data?.type === 'WORK' ? (
                    <div
                      className={styles['item-type']}
                      style={{
                        backgroundColor: '#CDC0F2',
                      }}
                    >
                      Insight
                    </div>
                  ) : (
                    <div
                      className={styles['item-type']}
                      style={{
                        backgroundColor: '#C0DAF2',
                      }}
                    >
                      Q & A
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles['item-windows-header']}>
              <AvatarRow showMembership data={data._userId as IUser} />
              <div className={styles['postType-date-wrapper']}>
                <div
                  className={styles['item-title']}
                  style={{ marginBottom: '0' }}
                >
                  {data?.type === 'WORK' ? (
                    <div
                      className={styles['item-type']}
                      style={{
                        backgroundColor: '#CDC0F2',
                      }}
                    >
                      Insight
                    </div>
                  ) : (
                    <div
                      className={styles['item-type']}
                      style={{
                        backgroundColor: '#C0DAF2',
                      }}
                    >
                      Q & A
                    </div>
                  )}
                </div>

                <span className={styles['post-date']}>
                  {formatDateStr(data.createdOn)}
                </span>
              </div>
            </div>
          )}
          <div>
            {data?.content && (
              <>
                <p
                  ref={contentRef}
                  onClick={() => {
                    !token ? setSignupModal(true) : handleToggleContent();
                  }}
                  className={`${styles['item-desc']} ${
                    isExpanded ? styles['expanded'] : ''
                  }`}
                  dangerouslySetInnerHTML={{ __html: data?.content }}
                />
                {isClamped && isExpanded && (
                  <span
                    className={styles['toggle-button']}
                    onClick={() => {
                      handleToggleContent();
                    }}
                  >
                    Show Less &nbsp;
                    <FontAwesomeIcon
                      icon={faCaretUp}
                      style={{ marginTop: '2px' }}
                    />
                  </span>
                )}
              </>
            )}
          </div>

          {data?.attachments?.length > 0 && (
            <div className={styles['attachment-list']}>
              <AttachmentList attachments={data.attachments} data={data} />
            </div>
          )}

          {window.innerWidth > 700 && (
            <div className={styles['dashboard-post-hr']} />
          )}
          <div className={styles['item-interaction']}>
            <Button
              className={classNames(
                styles['interaction-btn'],
                styles['answer-btn']
              )}
              color="transparent"
              onClick={() => {
                !token ? setSignupModal(true) : showComments();
              }}
            >
              {data.type === 'WORK' ? (
                <>
                  <img src="/images/commentIcon.svg" alt="" />
                  &nbsp;Comment
                </>
              ) : (
                <>
                  <img src="/images/answerIcon.svg" alt="" />
                  &nbsp;Answer
                </>
              )}
              &nbsp;({data?.commentCount})
            </Button>
            {data.type === 'QUESTION' && (
              <Request
                onReset={() => {
                  console.log('Reset');
                }}
                onRequest={(args) => {
                  handleRequestSend(
                    user,
                    args as unknown as IUser[],
                    data as IPost,
                    'POST'
                  );
                }}
                label="Request"
              />
            )}
            {data.type === 'WORK' && (
              <Request
                onReset={() => {
                  console.log('Reset');
                }}
                onRequest={(args) => {
                  handleRequestSend(
                    user,
                    args as unknown as IUser[],
                    data as IPost,
                    'POST'
                  );
                }}
                label="Share"
              />
            )}
            <PinButton
              self={self}
              //@ts-ignore
              pinId={data?.pins?.length ? true : false}
              selectedQues={data}
              type={Pin.POST}
            />
            {data?.type === 'WORK' && (
              <Button
                className={styles['interaction-btn']}
                color="transparent"
                onClick={() => {
                  if (isVoted) {
                    removeUpvote();
                  } else {
                    addUpvote();
                  }
                }}
                disabled={!token || loadingUpvotes}
              >
                <img
                  src={
                    isVoted
                      ? '/images/upvotedIcon.svg'
                      : '/images/upvoteIcon.svg'
                  }
                  alt="Upvote"
                />
                &nbsp;Upvote ({data?.upvoteCount || 0})
                {loadingUpvotes && (
                  <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                )}
              </Button>
            )}
            <div style={{ margin: '0 10px 0 auto' }}>
              {!isCopying ? (
                <FontAwesomeIcon
                  icon={faCopy}
                  onClick={() => handleCopyPost(data)}
                  style={{
                    cursor: 'pointer',
                    fontSize: '18px',
                    color: '#666',
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCheck}
                  className={styles['check-icon']}
                />
              )}
            </div>
            {isCopying && window.innerWidth <= 700 && (
              <div
                style={{
                  position: 'fixed',
                  bottom: '110px',
                  width: '100%',
                  margin: '0 auto',
                  paddingRight: '12px',
                  zIndex: '999',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    padding: '4px 8px',
                    border: '1px solid #ccc',
                    backgroundColor: '#e6e6e6',
                    width: 'max-content',
                    color: '#1a1a1a',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                  }}
                >
                  Copied to Clipboard
                </div>
              </div>
            )}
            {(self ||
              ['SUPER_ADMIN', 'ADMIN', 'MANAGER'].includes(user.role)) && (
              <Dropdown
                className={styles.menuButton}
                isOpen={showQuestionMenu}
                toggle={() => setShowQuestionMenu((old) => !old)}
                direction="down"
              >
                <DropdownToggle>
                  {updating ? (
                    <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" />
                  ) : (
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  )}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={handleEdit}>Edit</DropdownItem>
                  <DropdownItem onClick={handleDelete}>Delete</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
          {!(data?.type === 'QUESTION' && !user.isProfessional) &&
            isShowComment && (
              <Input
                type="text"
                className={styles['add-answer-input']}
                placeholder={
                  data?.type === 'WORK' ? 'Comment here' : 'Type your answer'
                }
                onClick={() => {
                  setSelectedQuestion(() => data);
                  setShow(true);
                }}
              />
            )}
          {isShowComment && (
            <CommentsList post={data} updatePost={updatePost} />
          )}
          {show && (
            <CreatePost
              initialPostData={editQuestion}
              //@ts-ignore
              onCreateComment={(comment) => {
                setData((prvQuestion) =>
                  prvQuestion
                    ? {
                        ...prvQuestion,
                        commentCount: (prvQuestion.commentCount || 0) + 1,
                      }
                    : prvQuestion
                );
              }}
              onUpdatePost={async () => {
                const res = await getPost(data?._id);
                setData(() => res);
              }}
              onUpdateComment={() => {}}
              show={show}
              onHide={() => {
                setEditQuestion(undefined);
                setSelectedQuestion(undefined);
                setShow(() => false);
              }}
              isAnswer={!editQuestion && !isWork}
              isComment={!editQuestion && isWork}
              ques={selectedQuestion}
            />
          )}
        </div>
      )}

      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
};

export default DashboardListItem;
