import { IPost } from 'src/services/post-service/post-service.interface';
import { IUser } from 'src/services/user-service/user-service.interface';

export interface IOption {
  value: string;
  label: string;
}

export const formatPostToOption = (posts: IPost[]): IOption[] => {
  return posts.map((post) => ({
    value: post._id,
    label: post.plainText,
  }));
};

export const formatUserToOption = (users: IUser[]): IOption[] => {
  return users.map((user) => ({
    value: user._id,
    label: user.name,
  }));
};

export const categoryMap = (self = false, isProfessional = false) => {
  if (!isProfessional && self) {
    return [
      {
        key: 'published-works',
        title: 'My Published Work',
        icon: '/images/publishedWorkIcon.svg',
      },
      {
        key: 'questions',
        title: 'My Question',
        icon: '/images/questionsIcon.svg',
      },

      {
        key: 'networks',
        title: 'My Network',
        icon: '/images/networkIcon.svg',
      },
      {
        key: 'pins',
        title: 'My Pin',
        icon: '/images/pinnedIcon.svg',
      },
      {
        key: 'meetings',
        title: 'My Meetings',
        icon: '/images/meetingCalendarLogo.png',
      },
    ];
  } else if (isProfessional && self) {
    return [
      {
        key: 'published-works',
        title: 'My Published Work',
        icon: '/images/publishedWorkIcon.svg',
      },
      {
        key: 'questions',
        title: 'My Question',
        icon: '/images/questionsIcon.svg',
      },
      {
        key: 'answers',
        title: 'My Answers / Comments',
        icon: '/images/commentsIcon.svg',
      },
      {
        key: 'networks',
        title: 'My Network',
        icon: '/images/networkIcon.svg',
      },
      {
        key: 'pins',
        title: 'My Pin',
        icon: '/images/pinnedIcon.svg',
      },
      {
        key: 'meetings',
        title: 'My Meetings',
        icon: '/images/meetingCalendarLogo.png',
      },
    ];
  } else if (isProfessional && !self) {
    return [
      {
        key: 'published-works',
        title: 'Published Work',
        icon: '/images/publishedWorkIcon.svg',
      },
      {
        key: 'questions',
        title: 'Question',
        icon: '/images/questionsIcon.svg',
      },
      {
        key: 'answers',
        title: 'Answers / Comments',
        icon: '/images/commentsIcon.svg',
      },
      {
        key: 'networks',
        title: 'Network',
        icon: '/images/networkIcon.svg',
      },
    ];
  } else if (!isProfessional && !self) {
    return [
      {
        key: 'published-works',
        title: 'Published Work',
        icon: '/images/publishedWorkIcon.svg',
      },
      {
        key: 'questions',
        title: 'Question',
        icon: '/images/questionsIcon.svg',
      },

      {
        key: 'networks',
        title: 'Network',
        icon: '/images/networkIcon.svg',
      },
    ];
  } else {
    return [];
  }
};
