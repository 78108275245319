import styles from './navbar.module.css';
import { useNavigate } from 'react-router';

const Navbar = () => {
  const router = useNavigate();

  return (
    <div className={styles['wc-nav']}>
      <div
        className={styles['logo-container']}
        onClick={() => router('/dashboard')}
      >
        <img
          src="/images/logo.svg"
          alt="home 1"
          width={0}
          height={0}
          style={{ width: '100%', height: 'inherit', filter: 'invert(1)' }}
        />
      </div>
    </div>
  );
};

export default Navbar;
