import React, { useRef, useState } from 'react';
import { uploadAttachment } from 'src/services/attachment-service/attachment-service.client';
import { dataUrlToFile } from 'src/utils/urls';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import styles from './events.layout.module.css';
import AvatarEditor from 'react-avatar-editor';
import Button from 'react-bootstrap/Button';
import EventLogo from './EventLogo';
import SignupModal from '../stepper/SignupModal';
import { SuccessLottie } from '../lotties/success';
import { LoadingLottie } from '../lotties/loading';
import { FailureLottie } from '../lotties/failure';
import { createEvent } from 'src/services/event-service/event-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const resizeImage = (
  file: File,
  targetSizeKB: number
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.src = event.target?.result as string;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

        const maxSize = targetSizeKB * 1024;
        let width = img.width;
        let height = img.height;

        // maintaining aspect ratio
        const ratio = width / height;
        if (width > height) {
          width = Math.min(Math.sqrt(maxSize / ratio), img.width);
          height = width / ratio;
        } else {
          height = Math.min(Math.sqrt(maxSize * ratio), img.height);
          width = height * ratio;
        }

        canvas.width = width;
        canvas.height = height;

        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        ctx.drawImage(img, 0, 0, width, height);
        const resizedDataUrl = canvas.toDataURL(file.type);
        resolve(resizedDataUrl);
      };
      img.onerror = (error) => {
        reject(error);
      };
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

const CreateEvent = () => {
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const [refresh, setRefresh] = useState(1);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const [eventsData, setEventsData] = useState({
    eventName: '',
    joiningUrl: '',
    eventDesc: '',
    eventImage: '',
    interested: false,
    startDateTime: '',
    endDateTime: '',
    cpeCredit: '',
    eventType: '',
  });
  const [image, setImage] = useState<File | null>(null);
  const editorRef = useRef<AvatarEditor | null>(null);
  const [zoom, setZoom] = useState(0.6);

  const [showImageModal, setShowImageModal] = useState(false);
  const [eventType, setEventType] = useState('');

  const onStartDateTimeChange = (e: any) => {
    setStartDateTime(e.target.value);
    setEventsData({ ...eventsData, startDateTime: e.target.value });
  };

  const onEndDateTimeChange = (e: any) => {
    setEndDateTime(e.target.value);
    setEventsData({ ...eventsData, endDateTime: e.target.value });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'eventTypeOnline' || name === 'eventTypeOffline') {
      setEventType(value);

      setEventsData({ ...eventsData, eventType: value });
    } else {
      setEventsData({ ...eventsData, [name]: value });
    }
  };

  const handleImageChange = async (e: any) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setShow(false);
      setImage(selectedImage);
      handleShowImageModal();
    }
  };

  const handleShow = () => {
    setShow(true);
  };
  const handleShowImageModal = () => {
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setShow(true);
  };
  const handleSaveImage = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage();
      const imageDataUrl = canvas.toDataURL();
      // @ts-ignore
      const imgFile = await dataUrlToFile(imageDataUrl);
      const resizedDataUrl = await resizeImage(imgFile, 256);
      setEventsData({ ...eventsData, eventImage: resizedDataUrl });
      handleCloseImageModal();
      setShow(true);
    }
  };
  const [searchParams] = useSearchParams();
  const urlParams = new URLSearchParams(searchParams);
  const navigate = useNavigate();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      let attachmentRes = [];
      if (eventsData?.eventImage) {
        const imgFile = await dataUrlToFile(eventsData.eventImage);
        attachmentRes = await uploadAttachment([imgFile]);
      }

      await createEvent(
        attachmentRes,
        eventsData.eventName,
        eventsData.startDateTime,
        eventsData.endDateTime,
        eventsData.joiningUrl,
        eventsData.eventDesc,
        eventsData.cpeCredit,
        eventsData.eventType
      );

      setEventsData({
        eventName: '',
        joiningUrl: '',
        eventDesc: '',
        eventImage: '',
        startDateTime: '',
        endDateTime: '',
        interested: false,
        cpeCredit: '',
        eventType: '',
      });

      setEventType('');
      setStartDateTime('');
      setEndDateTime('');
      setRefresh(refresh + 1);

      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setShow(false);
        if (window.location.href.includes('refresh=true')) {
          urlParams.set('refresh', 'false');
          navigate(`/events?${urlParams.toString()}`);
        } else {
          urlParams.set('refresh', 'true');
          navigate(`/events?${urlParams.toString()}`);
        }
      }, 2500);
    } catch (error) {
      console.error('Error creating event:', error);
      setLoading(false);
      setFailed(true);
      setTimeout(() => {
        setFailed(false);
        setShow(false);
      }, 2500);
    }
  };
  const token = localStorage.getItem('token') || '';
  const [signupModal, setSignupModal] = useState(false);

  return (
    <>
      <div className={styles['create-event-btn']}>
        {window.innerWidth > 700 ? (
          <Button
            variant="secondary"
            size="lg"
            onClick={() => {
              !token ? setSignupModal(true) : handleShow();
            }}
          >
            <EventLogo fill="var(--grey-grey-00, #FFF)" /> &nbsp; Create Event
          </Button>
        ) : (
          <div
            onClick={() => {
              !token ? setSignupModal(true) : handleShow();
            }}
            className={styles['create-event-toggle-btn-mobile']}
          >
            {' '}
            <Button>
              <EventLogo fill="var(--grey-grey-00, #FFF)" />
            </Button>
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={styles['create-event-modal']}
      >
        {' '}
        {success && <SuccessLottie height={410} />}
        {loading && <LoadingLottie height={410} />}
        {failed && <FailureLottie height={210} />}
        {!success && !loading && !failed && (
          <Form onSubmit={handleSubmit}>
            <Modal.Header
              closeButton
              className={styles['create-event-modal-header']}
            >
              <Modal.Title style={{ fontSize: '16px' }}>
                Create Event
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ display: 'flex' }}>
                <span
                  className={styles['create-event-modal-label']}
                  style={{ marginRight: '20px', paddingLeft: '5px' }}
                >
                  Event Type :
                </span>
                <Form.Group
                  className="mb-3"
                  controlId="eventTypeOnline"
                  style={{ marginRight: '20px' }}
                >
                  <div
                    className={styles['create-event-modal-label']}
                    style={{ display: 'flex' }}
                  >
                    <input
                      type="radio"
                      name="eventTypeOnline"
                      value="online"
                      onChange={handleChange}
                      checked={eventType === 'online'} // Add checked attribute
                    />
                    &nbsp; Online Event
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="eventTypeOffline">
                  <div
                    className={styles['create-event-modal-label']}
                    style={{ display: 'flex' }}
                  >
                    {' '}
                    <input
                      type="radio"
                      name="eventTypeOffline"
                      value="offline"
                      onChange={handleChange}
                      checked={eventType === 'offline'} // Add checked attribute
                    />{' '}
                    &nbsp; Offline Event
                  </div>
                </Form.Group>
              </div>
              <Form.Group controlId="eventImage">
                {eventsData.eventImage && (
                  <Form.Label className={styles['event-image-display']}>
                    <img
                      src={eventsData?.eventImage}
                      alt="eventImage"
                      className={styles['create-event-image']}
                    />
                  </Form.Label>
                )}

                {eventsData.eventImage ? (
                  <div
                    className={styles['createEvent-changeImageWrapper']}
                    onClick={() =>
                      setEventsData((prevFormData) => ({
                        ...prevFormData,
                        eventImage: '',
                      }))
                    }
                  >
                    Change Image &nbsp;
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </div>
                ) : (
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    placeholder="Event Image"
                    style={{ marginBottom: '15px' }}
                    className={styles['create-event-modal-label']}
                  />
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="eventName">
                <Form.Control
                  type="text"
                  name="eventName"
                  value={eventsData.eventName}
                  onChange={handleChange}
                  placeholder="Event name"
                  maxLength={40}
                  required
                  className={styles['create-event-modal-label']}
                />
              </Form.Group>
              <div
                style={{
                  display: 'flex',
                  gap: '20px',
                  width: '100%',
                }}
              >
                {' '}
                <Form.Group
                  className="mb-3"
                  controlId="startDateTime"
                  style={{ width: '50%' }}
                >
                  <Form.Label
                    className={styles['create-event-modal-label']}
                    style={{ paddingLeft: '5px' }}
                    for="startDateTime"
                  >
                    Starts at :
                  </Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="startDateTime"
                    value={startDateTime}
                    onChange={onStartDateTimeChange}
                    required
                    className={styles['create-event-modal-label']}
                    placeholder="Stats at"
                  ></Form.Control>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="endDateTime"
                  style={{ width: '50%' }}
                >
                  <Form.Label
                    className={styles['create-event-modal-label']}
                    for="endDateTime"
                    style={{ paddingLeft: '5px' }}
                  >
                    Ends at :
                  </Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="endDateTime"
                    value={endDateTime}
                    onChange={onEndDateTimeChange}
                    className={styles['create-event-modal-label']}
                    required
                  ></Form.Control>
                </Form.Group>
              </div>

              <Form.Group className="mb-3" controlId="joiningUrl">
                {eventType === 'offline' ? (
                  <Form.Control
                    type="text"
                    placeholder={'Event location'}
                    name="joiningUrl"
                    value={eventsData.joiningUrl}
                    onChange={handleChange}
                    className={styles['create-event-modal-label']}
                    maxLength={50}
                    required
                  />
                ) : (
                  <Form.Control
                    type="text"
                    placeholder={'Joining url / Webinar or Payment Link'}
                    name="joiningUrl"
                    value={eventsData.joiningUrl}
                    onChange={handleChange}
                    className={styles['create-event-modal-label']}
                    required
                  />
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="cpeCredit">
                <Form.Control
                  type="text"
                  placeholder="CPE credit"
                  name="cpeCredit"
                  value={eventsData.cpeCredit}
                  onChange={handleChange}
                  className={styles['create-event-modal-label']}
                />
              </Form.Group>

              <Form.Group controlId="eventDesc">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Event description (optional)"
                  name="eventDesc"
                  value={eventsData.eventDesc}
                  onChange={handleChange}
                  className={styles['create-event-modal-label']}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px 16px',
              }}
            >
              <Button
                variant="secondary"
                size="lg"
                type="submit"
                style={{ width: '100%' }}
                className={styles['create-event-modal-submit']}
              >
                Create event
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Modal>
      {showImageModal && (
        <Modal
          show={showImageModal}
          onHide={handleCloseImageModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className={styles['image-modal-header']} closeButton>
            <Modal.Title className={styles['image-modal-header-title']}>
              Edit Image
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {image && (
              <div className={styles['edit-image-modal']}>
                <AvatarEditor
                  ref={editorRef}
                  image={image}
                  border={0}
                  color={[255, 255, 255, 0.9]}
                  scale={zoom}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className={styles['edit-image-footer']}>
              <div className={styles['zoom-rotate-wrapper']}>
                <div className={styles['zoom-labe-input-wrapper']}>
                  <label className={styles['zoom-rotate-label']}>Zoom:</label>
                  <Form.Control
                    type="range"
                    min="0.6"
                    max="3"
                    step="0.1"
                    value={zoom}
                    onChange={(e) => setZoom(parseFloat(e.target.value))}
                    className={styles['zoom-rotate-input']}
                  />
                </div>
              </div>

              <div className={styles['edit-img-savecancel-wrapper']}>
                <Button
                  className={styles['image-modal-cancel']}
                  onClick={handleCloseImageModal}
                >
                  Cancel
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleSaveImage}
                  className={styles['image-modal-save']}
                >
                  Save Image
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      <SignupModal signupModal={signupModal} setSignupModal={setSignupModal} />
    </>
  );
};

export default CreateEvent;
