import React, { useEffect, useState } from 'react';
import { fetchUserMeetings } from 'src/services/meetings-service/meetings-service';
import MeetingViewItem from './MeetingViewItem';
import { IMeetings } from 'src/services/meetings-service/meeting-interface';
import styles from './meetingViewItem.module.css';
import NoData from '../no-data';
import BottomBar from '../bottombar/BottomBar';
export default function Meetings() {
  const [meetingList, setMeetingList] = useState<IMeetings[]>();
  const getUserMeetings = async () => {
    const meetings = await fetchUserMeetings();
    setMeetingList(() => meetings?.data);
  };
  useEffect(() => {
    window.alert(meetingList?.length);
    getUserMeetings();
  }, []);

  return (
    <div className={styles.meetingParent}>
      {!meetingList || meetingList === undefined || meetingList?.length <= 0 ? (
        <NoData />
      ) : (
        meetingList
          // ?.filter((meeting: any) => new Date(meeting.startTime) > new Date())
          .map((meeting: any) => (
            <div key={meeting?._id}>
              <MeetingViewItem
                meeting={meeting}
                refreshMeetings={getUserMeetings}
              />
            </div>
          ))
      )}
      {window?.innerWidth < 700 && <BottomBar />}
    </div>
  );
}
