import Modal from 'react-bootstrap/Modal';

import { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import styles from './change-password.module.css';

import { Button } from 'reactstrap';
import { UserContext } from 'src/context/user.context';
import { updateUser } from 'src/services/user-service/user-servicec';
import { LoadingLottie } from '../lotties/loading';
import { SuccessLottie } from '../lotties/success';
import { ValidationMessage } from '../validation-message';

type ICreatePostProps = {
  show: boolean;
  onHide: () => void;
};

export default function ChangePasswordForm(props: ICreatePostProps) {
  const { id } = useContext(UserContext);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const resetForm = (form: HTMLFormElement) => {
    if (form) {
      form.reset();
    }
    setPassword('');
    setLoading(false);
  };

  const isConfirmSame = password === confirmPassword;

  async function handleExperience(event: any): Promise<void> {
    const { onHide } = props;
    const form = event.currentTarget;
    if (form.checkValidity() === false || !isConfirmSame) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      try {
        if (password) {
          await updateUser(id, { password });

          setLoading(false);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            onHide();
            resetForm(form);
          }, 2500);
        }
      } catch (e) {
        resetForm(form);
      }
    }
    setValidated(true);
  }

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      className={styles.changePasswordModal}
    >
      {!loading && !success && (
        <>
          <Modal.Header closeButton>
            {' '}
            <h5>Change password</h5>{' '}
          </Modal.Header>
        </>
      )}
      <Modal.Body>
        {success && <SuccessLottie height={410} />}
        {loading && <LoadingLottie height={410} />}
        {!success && !loading && (
          <Form noValidate validated={validated} onSubmit={handleExperience}>
            <Form.Group className={styles.passwordContainer}>
              <Form.Control
                placeholder="Password"
                value={password}
                type={showPassword ? 'text' : 'password'}
                required
                onChange={(e) => setPassword(e.target.value)}
                style={{ border: '1px solid #E6E6E6', borderRadius: '12px' }}
              />
              {password.length > 0 && (
                <Button
                  color="transparent"
                  onClick={() => setShowPassword((prev) => !prev)}
                  className={styles.showPasswordButton}
                >
                  {showPassword ? (
                    <img alt="icon" src="/images/eyeOpenIcon.svg" />
                  ) : (
                    <img alt="icon" src="/images/eyeCloseIcon.svg" />
                  )}
                </Button>
              )}
              <Form.Control.Feedback type="invalid">
                <ValidationMessage>This field is required.</ValidationMessage>
              </Form.Control.Feedback>
            </Form.Group>
            <br />
            <Form.Group className={styles.passwordContainer}>
              <Form.Control
                placeholder="Confirm password"
                value={confirmPassword}
                type={showConfirmPassword ? 'text' : 'password'}
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{ border: '1px solid #E6E6E6', borderRadius: '12px' }}
              />
              {confirmPassword?.length > 0 && (
                <Button
                  color="transparent"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                  className={styles.showPasswordButton}
                >
                  {showConfirmPassword ? (
                    <img alt="icon" src="/images/eyeOpenIcon.svg" />
                  ) : (
                    <img alt="icon" src="/images/eyeCloseIcon.svg" />
                  )}
                </Button>
              )}
              <Form.Control.Feedback type="invalid">
                <ValidationMessage>This field is required.</ValidationMessage>
              </Form.Control.Feedback>
              {!isConfirmSame && confirmPassword.length > 0 && (
                <Form.Control.Feedback>
                  <ValidationMessage>
                    Both passwords are not same.
                  </ValidationMessage>
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <br />
            <div className={styles.postBtnWrapper}>
              <button type="submit" className={styles.postBtn}>
                Update
              </button>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
}
