import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useEffect, useState } from 'react';
import { fetchUser, updateUser } from 'src/services/user-service/user-servicec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faEnvelope,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Pagination from '@mui/material/Pagination';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './adminPanel.module.css';
import MembershipModal from './membershipModal';

const excludedKeys = [
  'isHideContactNumber',
  'isHideEmailAddress',
  'last',
  'attempts',
  '_id',
  'picture',
  '__v',
  'memberships',
  'id',
  'updatedOn',
  'skillSet',
  'achievement',
];

const MainBody = () => {
  const userTableHeading = [
    'Name',
    'Email Address',
    'Contact Number',
    'City',
    'State',
    'Membership Name',
    'Membership Number',
    'Created At',
    'Action',
  ];
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesSize, setPageSize] = useState(100);
  const dropDownList = ['INACTIVE', 'ACTIVE'];
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState('ACTIVE');
  const [action, setAction] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const [showMembershipModal, setShowMembershipModal] = useState(false);
  const [selectedMembership, setSelectedMembership] = useState({});

  const handleClose = () => setShowModal(false);

  const style = {
    margin: '10px',
  };

  const convertToCSV = (array) => {
    const header = Object.keys(array[0]).join(',') + '\n';
    const rows = array.map((item) => Object.values(item).join(',')).join('\n');
    return header + rows;
  };

  const handleMembershipToggle = (tableData, membership) => {
    setSelectedMembership(membership);
    setShowMembershipModal(true);
    setSelectedUser(tableData);
  };

  const handleChangeList = (status) => {
    setStatus(status);
  };

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getFilteredData = (data) => {
    const filteredData = data.map((item) => {
      const { memberships, ...rest } = item;
      const extractMemberShipName = memberships.map(
        (membership) => membership.name
      );
      const extractMemberShipId = memberships.map(
        (membership) => membership.id
      );
      const filteredItem = {
        ...rest,
        membershipName: extractMemberShipName.join(' '),
        membershipId: extractMemberShipId.join(' '),
      };
      excludedKeys.forEach((key) => delete filteredItem[key]);
      return filteredItem;
    });
    return filteredData;
  };

  const handleDownlaod = () => {
    setIsLoading(true);
    const currentDate = getCurrentDate();
    const fileName = `user_data_${currentDate}.csv`;
    const csvData = convertToCSV(getFilteredData(data));
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(url);
    setIsLoading(false);
  };

  const handleStatusClick = (rowData) => {
    setSelectedUser(rowData);
    setShowModal(true);
  };

  const handleConfirmStatus = async (data) => {
    setIsLoading(true);
    setAction(data.id + data.status);
    try {
      let UserId = data.id;
      const payload = {
        status: status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
      };
      await updateUser(UserId, payload);
      fetchData();
      setIsLoading(false);
      setShowModal(false);
    } catch (error) {
      console.log('error while updating the user', error);
      setShowModal(false);
    }
  };
  const [isShowNotVeified, setIsShowNotVeified] = useState(false);

  const fetchData = async () => {
    try {
      let params = {
        status: status,
        pageNumber: currentPage,
        pageSize: pagesSize,
        sortBy: 'createdOn',
      };
      if (isShowNotVeified) {
        params.showNotVerified = true;
      }
      const fetchData = await fetchUser(params);
      setData(fetchData?.data);
      setIsLoading(false);
      setTotalPages(fetchData?.data?.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, action, currentPage, pagesSize, isShowNotVeified]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event, newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const onStartDateChange = (date) => {
    setStartDate(date);
  };

  const onEndDateChange = (date) => {
    setEndDate(date);
  };

  const hanldeNotVerifiedUser = () => {
    setIsShowNotVeified(() => !isShowNotVeified);
  };

  return (
    <div style={{ padding: '20px' }}>
      {showMembershipModal && (
        <MembershipModal
          showModal={showMembershipModal}
          setShowMembershipModal={setShowMembershipModal}
          setSelectedMembership={setSelectedMembership}
          membership={selectedMembership}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          fetchData={fetchData}
        />
      )}
      <div className="text-center mt-5">
        {isLoading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>
      {showModal && (
        <div>
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Review Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                <>
                  Do you{' '}
                  <strong>
                    {status === 'INACTIVE' ? 'Confirm' : 'Cancel'}
                  </strong>{' '}
                  review for <strong>{selectedUser?.name}</strong>
                </>
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => handleConfirmStatus(selectedUser)}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      <div>
        <div className={styles['header-container']}>
          <div className={styles['date-inputs']}>
            <div className={styles['dateLabelWrapper']}>
              {' '}
              <label htmlFor="">Start Date</label>
              <DatePicker
                selected={startDate}
                onChange={onStartDateChange}
                dateFormat="yyyy-MM-dd"
                placeholderText="Start Date"
                className={styles['date-picker']}
              />
            </div>
            <div className={styles['dateLabelWrapper']}>
              <label htmlFor="">End Date </label>
              <DatePicker
                selected={endDate}
                onChange={onEndDateChange}
                dateFormat="yyyy-MM-dd"
                placeholderText="End Date"
                className={styles['date-picker']}
              />
            </div>
          </div>
          <div>
            <Button
              variant="secondary"
              onClick={() => {
                hanldeNotVerifiedUser();
              }}
            >
              {isShowNotVeified ? 'Show All' : 'Show Not Verified'}
            </Button>
          </div>
          <div className={styles['userActiveDropdown']}>
            <Dropdown data-bs-theme="dark">
              <Dropdown.Toggle
                id="dropdown-button-status-change"
                variant="secondary"
                className={styles['userActiveDropdownToggle']}
              >
                Review Status
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {dropDownList.map((data, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleChangeList(data)}
                    >
                      {data}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <hr />
      <div className={styles['userlistDownloadPrintWrapper']}>
        <FontAwesomeIcon
          onClick={() => handleDownlaod()}
          style={style}
          icon={faDownload}
        />
        <FontAwesomeIcon style={style} icon={faEnvelope} />
        <FontAwesomeIcon style={style} icon={faPrint} />
      </div>
      <Table className={styles['admin-table']} responsive bordered hover>
        <thead>
          <tr className={styles['admin-table-header']}>
            <th>#</th>
            {userTableHeading.map((thData, index) => (
              <th key={index} className={styles['admin-table-header']}>
                {thData}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data
            .filter((user) => user.memberships.length > 0)
            .map((tableData, index) => (
              <tr key={tableData.id} className={styles['admin-table-row']}>
                <td className={styles['admin-table-cell']}>{index + 1}</td>
                <td className={styles['admin-table-cell']}>{tableData.name}</td>
                <td className={styles['admin-table-cell']}>
                  {tableData.emailAddress}
                </td>
                <td className={styles['admin-table-cell']}>
                  {tableData.contactNumber}
                </td>
                <td className={styles['admin-table-cell']}>{tableData.city}</td>
                <td className={styles['admin-table-cell']}>
                  {tableData.state}
                </td>
                {/* <td className={styles['admin-table-cell']}>
                <img
                  className={styles['profile-pic']}
                  src={
                    tableData?.picture?.path
                      ? resolveBEAsset(tableData?.picture?.path || '')
                      : '/images/defaultProfilePic.svg'
                  }
                  alt="profile-pic"
                  width="100"
                  height="100"
                />
              </td> */}
                <td className={styles['admin-table-cell']}>
                  <ul className={styles['membership-list']}>
                    {tableData.memberships
                      ?.filter((item) => item.name !== 'NONE')
                      .map((membership) => (
                        <li
                          key={membership.id}
                          className={styles['membership-list-label']}
                        >
                          <label>
                            <input
                              className={styles['membership-list-checkbox']}
                              type="checkbox"
                              checked={membership.isVerified}
                              onClick={() =>
                                !membership.isVerified
                                  ? handleMembershipToggle(
                                      tableData,
                                      membership
                                    )
                                  : false
                              }
                            />
                            {membership.name}
                          </label>
                        </li>
                      ))}
                  </ul>
                </td>
                <td className={styles['admin-table-cell']}>
                  <ul className={styles['membership-number-list']}>
                    {tableData.memberships
                      ?.filter((item) => item.number !== 'None Selected')
                      .map((membership, index) => (
                        <li
                          key={index}
                          className={styles['membership-number-item']}
                        >
                          {membership.number}
                        </li>
                      ))}
                  </ul>
                </td>
                {/* <td className={styles['admin-table-cell']}>{tableData.role}</td> */}
                {/* <td className={styles['admin-table-cell']}>
                  {tableData.status}
                </td> */}
                <td className={styles['admin-table-cell']}>
                  <div className={styles['date-container']}>
                    <span className={styles['date']}>
                      {new Date(tableData.createdOn).toLocaleDateString()}
                    </span>
                    <span className={styles['time']}>
                      {new Date(tableData.createdOn).toLocaleTimeString()}
                    </span>
                  </div>
                </td>
                {/* <td className={styles['admin-table-cell']}>
                <div className={styles['date-container']}>
                  <span className={styles['date']}>
                    {new Date(tableData.updatedOn).toLocaleDateString()}
                  </span>
                  <span className={styles['time']}>
                    {new Date(tableData.updatedOn).toLocaleTimeString()}
                  </span>
                </div>
              </td> */}
                <td className={styles['admin-table-cell']}>
                  <Button
                    className={`${styles['status-button']} ${
                      tableData.status === 'ACTIVE'
                        ? styles['status-button-success']
                        : styles['status-button-secondary']
                    }`}
                    onClick={() => handleStatusClick(tableData)}
                  >
                    {tableData.status}
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Pagination
        count={totalPages}
        page={currentPage}
        onRowsPerPageChange={handlePageSizeChange}
        onChange={handlePageChange}
        rowCount={totalPages * pagesSize}
      />
    </div>
  );
};

export default MainBody;
